import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpModule, JsonpModule } from '@angular/http';

import {
  KzMaskDirective,
  KzMaskCurrencyDirective,
  DynamicFormComponent,
  DynamicFormControlComponent,
  ErrorMessageComponent,
  ErrorSummaryComponent,
  FormControlService,
  UppercasePipe,
  DocumentPipe,
  GreetPipe,
  SafePipe,
  SecondsToTimePipe
} from './';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // No need to export as these modules don't expose any components/directive etc'
    HttpModule,
    JsonpModule
  ],
  declarations: [
    DynamicFormComponent,
    DynamicFormControlComponent,
    ErrorMessageComponent,
    ErrorSummaryComponent,
    UppercasePipe,
    DocumentPipe,
    GreetPipe,
    SafePipe,
    SecondsToTimePipe,
    KzMaskDirective,
    KzMaskCurrencyDirective
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Providers, Components, directive, pipes
    DynamicFormComponent,
    DynamicFormControlComponent,
    ErrorSummaryComponent,
    ErrorMessageComponent,
    UppercasePipe,
    DocumentPipe,
    GreetPipe,
    SafePipe,
    SecondsToTimePipe,
    KzMaskDirective,
    KzMaskCurrencyDirective
  ]

})
export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        FormControlService
      ]
    };
  }
}
