import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BillingService, NotificationService } from '../../../services';
import { PaymentCardModel } from 'app/models/payment-card';
import { PageBaseComponent } from 'app/components/pages/page-base.component';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './faturamento.component.html',
    styleUrls: ['./faturamento.component.css'],
})


export class FaturamentoComponent extends PageBaseComponent {

    public showWarningInvoicesPending = false;
    public invoices: any[] = [];
    public showLoading = true;

    constructor(private billingService: BillingService, private notificationService: NotificationService) {
        super();
    }

    public paymentCardModel: PaymentCardModel = new PaymentCardModel({

    });

    public ngOnInit() {
        this.loadInvoices();
    }

    openModalPayWithCard(identificator: string) {
        this.paymentCardModel.idInvoice = identificator;

        $('#modalPayWithCard').modal('show');
        $('.chosen-select').trigger('chosen:updated');
    }

    public loadInvoices() {
        this.billingService.getAllAssocied().subscribe(
            response => {
                this.invoices = response;

                for (let index = 0; index < this.invoices.length; index++) {
                    let element = this.invoices[index];

                    if (new Date(element.ExpiryDate) < new Date()) {
                        this.showWarningInvoicesPending = true;
                    }
                }
                this.showLoading = false;
            },
            err => {
                this.notificationService.showMessage('', err.Message, 'error');
                this.showLoading = false;
            }
        )
    }

    payWithCard(form: any, model: any, isValid: boolean) {

        if (!isValid) {
            return;
        }

        this.paymentCardModel.cardHolder = model.cardHolder;
        this.billingService.payWithCard(this.paymentCardModel).subscribe(
            response => {
                this.notificationService.showSuccessNotification('', 'Pagamento realizado com sucesso!');
                $('#modalPayWithCard').modal('hide');
                this.loadInvoices();
            },
            err => {
                if (err.Message) {
                    this.notificationService.showErrorNotification('', 'Erro ao efetivar pagamento: ' + err.Message);
                } else {
                    this.notificationService.showErrorNotification('', 'Erro ao efetivar pagamento.');
                }
            }
        );
    }

    regenerate(identificator: string) {
        this.billingService.regenerate(identificator).subscribe(
            response => {
                this.notificationService.showSuccessNotification('', 'Parcela regerada com sucesso!');
                this.loadInvoices();
            },
            err => {
                if (err.Message) {
                    this.notificationService.showErrorNotification('', 'Erro ao regerar parcela: ' + err.Message);
                } else {
                    this.notificationService.showErrorNotification('', 'Erro ao regerar parcela.');
                }
            }
        );
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {

    }

    openModalBoletoViewer(urlToBoleto) {
        window.open(urlToBoleto, '_blank');
    }

}
