
import {of as observableOf,  Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';
import { first } from 'rxjs/operators';

@Injectable()
export class MockDataService {

    constructor() {

    }

    public getAll(searchText: string, page: number, maxRows: number): Observable<any> {

        const tempRows = [];

        const firstId = ((page - 1) * maxRows) + 1;

        for (let i = 0; i < maxRows; i++) {
            tempRows.push({
                id: firstId + 1,
                name: 'Registro => ' + (firstId + i),
                data: '10/10/2017'
            });
        }

        return observableOf(tempRows);
    }

}
