
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  private _authentication = { isAuth: false, userName: '', useRefreshTokens: false, roles: Array(), token: '' };
  private headers: Headers;
  private _userData: any = {};

  constructor(private config: Configuration, private http: Http, private router: Router) {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
        this.headers.append('Accept', 'application/json');

        const authDataText = sessionStorage.getItem('authorizationData');
        if (authDataText) {
            const authData = JSON.parse(authDataText);

            this._authentication.isAuth = true;
            this._authentication.userName = authData.userName;
            this._authentication.useRefreshTokens = authData.useRefreshTokens;
            this._authentication.roles = authData.roles;
            this._authentication.token = authData.token;
        }

        const userDataText = sessionStorage.getItem('userData');
        if (userDataText) {
            this._userData = JSON.parse(userDataText);
        }
  }

  public isAuthenticated = (): boolean => {
      return this._authentication.isAuth;
  }

  public getToken = (): string => {
      return this._authentication.token;
  }

  public getUserName = (): string => {
      return this._authentication.userName;
  }

  public getUserData = (): any => {
    return this._userData;
  }

  public getShowMonitorOfAttendance = (): any => {
    return this._userData.ShowMonitorOfAttendance;
  }

  public login = (loginData: any): Observable<boolean> => {

    const data = 'username=' + loginData.userName + '&password=' + loginData.password + '&grant_type=password';

    return this.http.post(this.config.serverWithTokenUrl, data, { headers : this.headers }).pipe(
      map((response: Response) => {

        if (response.ok) {
            const token = response.json() && response.json().access_token;
            if (token) {
                let authorizationData = {};

                if (loginData.useRefreshTokens) {
                    authorizationData = { token: response.json().access_token, userName: loginData.userName, refreshToken: response.json().refresh_token, useRefreshTokens: true, roles: response.json().roles };
                }
                else {
                    authorizationData = { token: response.json().access_token, userName: loginData.userName, refreshToken: '', useRefreshTokens: false, roles: response.json().roles };
                }

                sessionStorage.setItem('authorizationData', JSON.stringify(authorizationData));
                this._authentication.isAuth = true;
                this._authentication.userName = loginData.userName;
                this._authentication.useRefreshTokens = loginData.useRefreshTokens;
                this._authentication.roles = response.json().roles;
                this._authentication.token = token;
                // return true to indicate successful login
                return true;
            } else {
                // return false to indicate failed login
                return false;
            }
        } else {
            return false;
        }

    }),
    catchError((error: any) => observableThrowError(error.json().error || 'Server error')), );
  }

  public logout = (): void => {
    sessionStorage.removeItem('authorizationData');

      this._authentication.isAuth = false;
      this._authentication.userName = '';
      this._authentication.useRefreshTokens = false;
  }

  public getMe(): Observable<any> {

        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this._authentication.token, 'Content-Type' : 'application/json', 'Accept': 'application/json' });
        const options = new RequestOptions({ headers: headers });

        // get customers from api
        return this.http.get(this.config.serverWithApiUrl + 'customer/user/getdetails' , options).pipe(
            map((response: Response) => {
                this._userData = response.json();
                sessionStorage.setItem('userData', JSON.stringify(this._userData));
                return this._userData;
            }),
            catchError((error: any) => observableThrowError(error.json().error || 'Server error')), );
    }
}
