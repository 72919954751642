import { RSSFontModel } from 'app/models';

export class RSSContentChannelModel {
    public IdRssContentChannel: string;
    public RssFont: RSSFontModel;
    public Description: string;
    public Url: string;

    public constructor( data: any = {}) {
        this.IdRssContentChannel = data.IdRssContentChannel || '';
        this.RssFont = data.RssFont || '';
        this.Description = data.Description || '';
        this.Url = data.Url || '';
    }
}
