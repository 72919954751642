import { UserModel } from 'app/models/user';


export class AdvertiserModel {
    public IdAdvertiser: number;
    public Name: string;
    public IdActivityBranch: number;
    public Phone: string;
    public Email: string;
    public DocumentId: string;
    public RegistrationNumber: string;
    public Deleted: boolean;
    public User: UserModel;

    public constructor( data: any = {}) {
        this.IdAdvertiser = data.IdAdvertiser || 0;
        this.Name = data.Name || '';
        this.IdActivityBranch = data.IdActivityBranch || 0;
        this.Phone = data.Phone || '';
        this.Email = data.Email || '';
        this.DocumentId = data.DocumentId || '';
        this.RegistrationNumber = data.RegistrationNumber || '';
        this.Deleted = data.Deleted || false;
        this.User = data.User || null;
    }
}
