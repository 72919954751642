import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth.service';
// import { User } from '../models/user';

const langs = ['en', 'fr', 'ru', 'he', 'zh', 'pt', 'pt-br', 'en-us'];
const langmatch = /en|es|pt/;

@Injectable()
export class AppTranslateService implements OnInit {
    private lang = 'us';
    // private currentUser: User;

    constructor(private translate: TranslateService, private authService: AuthService) {
        translate.addLangs( langs );

        const browserLang = this.translate.getBrowserLang().toLowerCase();
        const browserCultureLang = this.translate.getBrowserCultureLang().toLowerCase();

        const useLang = browserLang.match( langmatch ) ? browserCultureLang : 'pt-br';
        // console.log(useLang);

        // this language will be used as a fallback when a translation isn't found in the current language
        //translate.setDefaultLang( 'en' );
        translate.use(useLang);

        const userData = this.authService.getUserData();
        if (userData && userData.Culture) {
            translate.use(userData.Culture);
        }

        /*this.userServ.currentUser.subscribe(( user: User ) => {
            this.currentUser = user;

            // the lang to use, if the lang isn't available, it will use the current loader to get them
            let browserLang = this.translate.getBrowserLang();
            let browserCultureLang = this.translate.getBrowserCultureLang();
            console.log( 'Detected browser language: "' + browserCultureLang + '"' );

            // check if current User has a Preferred Language set, and it differs from his browser lang
            let useLang = 'en';
            let prefLang = ( this.currentUser ) ? this.currentUser.preferredLang : null;
            if ( !prefLang ) {
                useLang = browserLang.match( langmatch ) ? browserLang : 'en';
            } else {
                console.log( 'Detected User preferred language: "' + prefLang + '"' );
                useLang = prefLang.match( langmatch ) ? prefLang : 'en';
            }
            this.translate.use( useLang );
            console.log( 'Translation language has been set to: "' + useLang + '"' );
            // translate.use( 'ru' );
        });*/
    }

    public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
        return this.translate.instant(key, interpolateParams);
    }

    public get(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
        return this.translate.get(key, interpolateParams);
    }

    public ngOnInit() {
        // TODO
    }

    public getTranslate(): TranslateService {
        return this.translate;
    }

}
