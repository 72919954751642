export class BaseService {

    public replaceAll = function(str, searchStr, replaceStr) {
        // no match exists in string?
        if (str.indexOf(searchStr) === -1) {
            // return string
            return str;
        }

        // replace and remove first match, and do another recursirve search/replace
        return this.replaceAll(str.replace(searchStr, replaceStr), searchStr, replaceStr);
    }

}
