import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService, ViewerExecutionApiService, ConfigurationService } from 'app/services';
import { Observable } from 'rxjs';
// import { setTimeout } from 'timers';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { ViewerExecutiontModel } from 'app/models/viewer-execution';
import { DatePipe } from '@angular/common';
import { InsertionTypeModel, InsertionRemoveBatch } from 'app/models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './remocao-em-lote.component.html',
    styleUrls: ['./remocao-em-lote.component.css'],
})

export class RemocaoEmLoteComponent extends PageBaseComponent {

    public players: any[] = [];
    public lstTiposConteudo: InsertionTypeModel[] = [];
    public showLoading = false;

    public insertions: any[] = [];

    public viewerExecutiontData: ViewerExecutiontModel = new ViewerExecutiontModel({
        group: '',
        playerIdentificator: '',
        date: ''
    });

    constructor(private viewerExecutionApiService: ViewerExecutionApiService,
        private playerService: PlayerService,
        private notificationService: NotificationService,
        private configurationService: ConfigurationService) {
        super();
    }

    public ngOnInit() {

        this.loadInsertionTypes();

        this.viewerExecutiontData.group = true;

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;

                setTimeout(() => {
                    this.initChosen(function () {

                    });
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )

        this.viewerExecutiontData.date = this.getCurrentDate();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initDatePicker();
        this.initChosen(function () {

        });
    }

    public loadInsertionTypes() {
        this.showLoading = true;
        this.configurationService.getAvailableInsertionTypes().subscribe(
            response => {
                this.lstTiposConteudo = response as InsertionTypeModel[];
                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
    }

    removeInsertion(insertion, type, selectedPlayers) {
        let instance = this;

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover a inserção selecionada?', 'warning', function () {

            instance.viewerExecutionApiService.Delete(insertion, type, selectedPlayers).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Solicitação de remoção enviada com sucesso!');
                    instance.getExectionList();
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao solicitar a remoção!');
                }
            );

        }, function () {
            // Do nothing
        });
    }

    bathcRemoveInsertion() {
        let instance = this;

        let insertosToRemove = new Array<InsertionRemoveBatch>();


        this.insertions.forEach(item => {
            item.LstInsertionViewer.forEach(itemInsercao => {
                if (itemInsercao.ToRemove) {
                    let insertion = new InsertionRemoveBatch();
                    insertion.Identificator = itemInsercao.Identificator;
                    insertion.PlayerIdentificator = itemInsercao.PlayerIdentificator;
                    insertion.Type = itemInsercao.Type;
                    insertosToRemove.push(insertion);
                }
            })
        });

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover TODAS inserção selecionadas?', 'warning', function () {

            instance.viewerExecutionApiService.BatchDelete(insertosToRemove).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Solicitação de remoção enviada com sucesso!');
                    instance.getExectionList();
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao solicitar a remoção!');
                }
            );

        }, function () {
            // Do nothing
        });
    }

    public getExectionList() {

        let insertionsTypes = $('#insertionsTypes').val();
        let selectedPlayers = $('#selectedPlayers').val();

        this.showLoading = true;
        this.viewerExecutionApiService.GetAllInsertions(selectedPlayers, insertionsTypes).subscribe(
            response => {
                this.insertions = response;
                this.showLoading = false;
            }, err => {
                this.showLoading = false;
                if (err.status === 401 || err.status === 403) return;
                this.notificationService.showErrorMessage(err);
            }
        )
    }
}
