import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationApiService, NotificationService, WarningApiService } from '../../../services';

@Component({
    selector: 'home',
    templateUrl: './alertas.component.html',
    styleUrls: ['./alertas.component.css'],
})
export class AlertasComponent {

    public warnings: any[] = [];
    public showLoadingWarnings = true;

    constructor(private warningApiService: WarningApiService, private notificationService: NotificationService) {

    }

    public ngOnInit() {
        this.loadWarnings();
    }

    public loadWarnings() {
        this.warningApiService.getAllAssocied().subscribe(
            response => {
                this.warnings = response;
                this.showLoadingWarnings = false;
            },
            error => {
                this.showLoadingWarnings = false;
                if (error.status === 401 || error.status === 403) return;
                this.notificationService.showErrorMessage(error);
            }
        )
    }

    markedWarningAsRead(identificator: string) {

        this.showLoadingWarnings = true;
        this.warningApiService.markAsReaded(identificator).subscribe(
            response => {
                this.loadWarnings();
                this.notificationService.showSuccessNotification('Notificação removida');
            },
            error => {
                this.notificationService.showMessage('', error.Message, 'error');
                this.showLoadingWarnings = false;
            }
        )
    }

    public ngOnDestroy() {
    }
}
