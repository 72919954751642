export class OperationLogModel {
    public type: string;
    public playerIdentificator: string;
    public date: string;

    public constructor( data: any = {}) {
        this.type = data.type || '';
        this.playerIdentificator = data.playerIdentificator || '';
        this.date = data.date || '';
    }
}
