import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService, PlayerService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { Input } from '@angular/core';
import { TablePriceContentModel, TablePriceProductModel } from 'app/models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'envio-conteudo-tabela-preco',
    templateUrl: './envio-conteudo-tabela-preco.component.html',
    styleUrls: ['./envio-conteudo-tabela-preco.component.css'],
})
export class EnvioConteudoTabelaPrecoComponent extends PageBaseComponent {

    public players: any[] = [];
    public hasSelectedFiles = false;

    @Input('ngConteudo')
    public conteudo: TablePriceContentModel = new TablePriceContentModel();
    public produtos: TablePriceProductModel[] = [];

    public produtoAdd: TablePriceProductModel = new TablePriceProductModel();

    public coresFundo = [
        { id: '0', name: 'Azul' },
        { id: '1', name: 'Cinza Escuro' },
        { id: '2', name: 'Verde' },
        { id: '3', name: 'Laranja' },
        { id: '4', name: 'Salmão' },
        { id: '5', name: 'Original' },
    ];

    public tiposOfertas = [
        { id: '1', name: 'Açougue' },
        { id: '2', name: 'Peixaria' },
        { id: '3', name: 'Padaria' },
        { id: '4', name: 'Frios' },
    ];

    constructor(private notificationService: NotificationService, private playerService: PlayerService) {

        super();

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;
                this.initChosen();
            }, err => {
                if (err.status === 401 || err.status === 403) return;
                this.notificationService.showErrorMessage(err);
            }
        )
    }

    public ngOnInit() {

    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initChosen();
        this.initDatePicker();

        this.conteudo.InitialDate = this.getCurrentDate();
        this.conteudo.EndDate = this.getCurrentDate();
    }


    updateModel() {
        let selectedPlayers = $('#selectedPlayers_' + this.conteudo.TempId).val();
        this.conteudo.PlayersList = selectedPlayers;

        let initialDate = $('#initialDate_' + this.conteudo.TempId).val();
        let endDate = $('#endDate_' + this.conteudo.TempId).val();

        this.conteudo.InitialDate = initialDate;
        this.conteudo.EndDate = endDate;

        this.conteudo.LstTablePriceProduct = this.produtos;
    }

    initChosen() {
        let instance = this;

        super.initChosen(function () {
            instance.updateModel();
        });
    }

    initDatePicker() {
        let instance = this;
        setTimeout(() => {
            $('.bootstrap-datepicker').datepicker({
                dateFormat: 'dd-mm-yy',
                onSelect: function (date) {
                    instance.updateModel();
                }
            });
        }, 0);
    }

    openModalNovoProduto() {
        $('#modalNovoAnunciante').modal('show');
        $('.chosen-select').trigger('chosen:updated');
    }

    saveProduto() {
        this.produtos.push(
            this.produtoAdd
        );
        this.updateModel();
        this.produtoAdd = new TablePriceProductModel();
        $('#modalNovoProduto').modal('hide');
    }


    removeProduto(value) {
        let index = this.produtos.map(x => x.Description).indexOf(value)
        if (index > -1) {
            this.produtos.splice(index, 1);
        }
        this.updateModel();
    }
}
