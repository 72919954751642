import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService } from 'app/services';
import { Observable } from 'rxjs';
// import { setTimeout } from 'timers';

declare var jquery: any;
declare var $: any;
declare var CKEDITOR: any;

export class PageBaseComponent {

    initChosen(changeCallback: (source) => any) {
        let instance = this;
        setTimeout(() => {
            $('.chosen-select').chosen('destroy');
            $('.chosen-select').chosen().change(function() {
                let $selectAllOption = $(this).parent().find('option[value="select_all"]');
                if ($selectAllOption && $selectAllOption.length && $selectAllOption.prop('selected') != '') {
                    $(this).parent().find('option').prop('selected', 'true').parent().trigger('chosen:updated');
                    $selectAllOption.prop('selected', '').parent().trigger('chosen:updated');
                }

                if (changeCallback) {
                    changeCallback(this);
                }
            })
            $('.chosen-search').append('<i class="glyph-icon icon-search"></i>');
            $('.chosen-single div').html('<i class="glyph-icon icon-caret-down"></i>');
        }, 0);
    }

    initDatePicker() {
        setTimeout(() => {
            $('.bootstrap-datepicker').datepicker({
                dateFormat: 'dd/mm/yy',
                onSelect: function (date) {
                    $(this).change();
                }
            });
        }, 0);
    }

    initDatePickerWithCallback(changeCallback: (date: any) => any) {
        setTimeout(() => {
            $('.bootstrap-datepicker').datepicker({
                dateFormat: 'dd/mm/yy',
                onSelect: function (date) {
                    if (changeCallback) {
                        changeCallback(date);
                    }
                }
            });
        }, 0);
    }

    initCkEditor() {
        setTimeout(() => {
            $('.ckeditor').each(function() {
                CKEDITOR.replace(this);
            });
        }, 0);
    }

    initRangeSliderHours() {
        let instance = this;
        $('.range-slider-date').each(function() {
            let startHour = $(this).attr('data-start-hour') || 8;
            let startMinute = $(this).attr('data-start-minute') || 0;
            let endHour = $(this).attr('data-end-hour') || 18;
            let endMinute = $(this).attr('data-end-minute') || 0;

            $(this).dateRangeSlider({
                bounds: {min: new Date(2013, 0, 1), max: new Date(2013, 0, 1, 23, 59, 59)},
                defaultValues: {min: new Date(2013, 0, 1, startHour, startMinute), max: new Date(2013, 0, 1, endHour, endMinute)},
                formatter: function(value){
                    let hours = value.getHours(),
                        minutes = value.getMinutes();
                    return instance.TwoDigits(hours) + ':' + instance.TwoDigits(minutes);
                }
            });
        });
    }

    TwoDigits(val){
        if (val < 10){
             return '0' + val;
        }

        return val;
    }

    formatDateToParameterService(dateString: any) {
        let dateParts = dateString.split('/');
        let dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        let formattedDate = dateObject.toLocaleString('en-US');
        formattedDate = formattedDate.substring(0, formattedDate.indexOf(','));
        return formattedDate;
    }

    public getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
      }

    getCurrentDate() {
        let currentDate = new Date(); //current date
        let dd = (currentDate.getDate()).toString();
        let mm = (currentDate.getMonth() + 1).toString(); //January is 0!
        let yy = (currentDate.getFullYear()).toString();

        if (dd.length < 2) {
            dd = '0' + dd;
        }

        if (mm.length < 2) {
            mm = '0' + mm;
        }

        return dd + '/' + mm + '/' + yy;
    }

    limitarLinhas(event, maxRows: number) {

        let textarea = $(event.target),
            text = textarea.val(),
            numberOfLines = (text.match(/\n/g) || []).length + 1;

        if (event.which === 13 && numberOfLines >= maxRows ) {

            if ( numberOfLines > maxRows) {
                let newText = text.substring(0, text.indexOf('\n', text.indexOf('\n') + 1));
                textarea.val(newText);
            }

            return false;
        }

        if ( numberOfLines > maxRows) {
            let newText = text.substring(0, text.indexOf('\n', text.indexOf('\n') + 1));
            textarea.val(newText);
        }
    }

    limitarLinhasChange(event, maxRows) {

        let textarea = $(event.target),
            text = textarea.val(),
            numberOfLines = (text.match(/\n/g) || []).length + 1;

        console.log(numberOfLines);

        if ( numberOfLines > maxRows) {
            let newText = text.substring(0, text.indexOf('\n', text.indexOf('\n') + 1));
            textarea.val(newText);
        }
    }

    public groupBy(array, prop) {
        return array.reduce(function(groups, item) {
            const val = item[prop]
            groups[val] = groups[val] || []
            groups[val].push(item)
            return groups
        }, {})
    }
}
