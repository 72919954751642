import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'public-component',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PublicComponent {

    constructor() {

    }
}
