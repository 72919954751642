import { InsertionTypeModel } from 'app/models';
import { Guid } from 'app/services/guid';

export class PlayerExecutionCycleModel {
    public IdPlayer: string;
    public ExecutionCycleId: string;
    public InsertionType: InsertionTypeModel;
    public Sequence: number;
    public TempId: string;
    public InsertionTypeId: string;

    public constructor( data: any = {}) {
        this.TempId = Guid.newGuid().replace('-', '_');
        this.IdPlayer = data.IdPlayer || '';
        this.ExecutionCycleId = data.ExecutionCycleId || '';
        this.InsertionType = data.InsertionType;
        this.Sequence = data.Sequence || 0;
        this.InsertionTypeId = data.InsertionTypeId || '';
    }
}

export class PlayerProgrammingItemModel {
    public Identificator: string;
    public DescriptionType: string;
    public InsertionType: InsertionTypeModel;
    public Sequence: number;
    public TempId: string;
    public IdInsertion: number;
    public IdInsertionType: string;

    public constructor( data: any = {}) {
        this.TempId = Guid.newGuid().replace('-', '_');
        this.Identificator = data.Identificator || '';
        this.DescriptionType = data.DescriptionType || '';
        this.InsertionType = data.InsertionType;
        this.Sequence = data.Sequence || 0;
        this.IdInsertion = data.IdInsertion || 0;
        this.IdInsertionType = data.IdInsertionType || '';
    }
}
