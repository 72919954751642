import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService, PlayerService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { Input } from '@angular/core';
import { OffersContentModel, ImageContentModel } from 'app/models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'envio-conteudo-imagem',
    templateUrl: './envio-conteudo-imagem.component.html',
    styleUrls: ['./envio-conteudo-imagem.component.css'],
})
export class EnvioConteudoImagemComponent extends PageBaseComponent {

    public players: any[] = [];
    public hasSelectedFiles = false;

    @Input('ngConteudo')
    public conteudo: ImageContentModel = new ImageContentModel();

    constructor(private notificationService: NotificationService, private playerService: PlayerService) {

        super();

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;
                this.initChosen();
            }, err => {
                if (err.status === 401 || err.status === 403) return;
                this.notificationService.showErrorMessage(err);
            }
        )
    }

    public ngOnInit() {

    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initChosen();
        this.initDatePicker();

        this.conteudo.InitialDate = this.getCurrentDate();
        this.conteudo.EndDate = this.getCurrentDate();
    }


    updateModel() {
        let selectedPlayers = $('#selectedPlayers_' + this.conteudo.TempId).val();
        this.conteudo.PlayersList = selectedPlayers;

        let initialDate = $('#initialDate_' + this.conteudo.TempId).val();
        let endDate = $('#endDate_' + this.conteudo.TempId).val();

        this.conteudo.InitialDate = initialDate;
        this.conteudo.EndDate = endDate;
    }

    initChosen() {
        let instance = this;

        super.initChosen(function() {
            instance.updateModel();
        });
    }
}
