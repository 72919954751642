import { ContentBaseModel } from './content-base';

export class WebPageContentModel extends ContentBaseModel {
    public UrlPage: string;

    public constructor( data: any = {}) {
        super();
        this.UrlPage = data.UrlPage || '';
    }

    public isValid(): boolean {
        return (
          this.Title.length > 0 &&
          this.InitialDate.length > 0 &&
          this.UrlPage.length > 0 &&
          this.EndDate.length > 0 &&
          this.PlayersList.length > 0
        );
    }
}
