import { DefaultInsertionLengthModel } from 'app/models';

export class PlayerGeneralConfigurationModel {
    public IdPlayer: string;
    public CityCode: number;
    public CityName: string;
    public StandardMessage: string;
    public UseProxyServer: boolean;
    public ProxyServerIP: string;
    public ProxyServerPort: string;
    public SendMessageStoppedPlayer: boolean;
    public AcceptOfflineFiles: boolean;
    public IdLiveUrl: string;
    public IdProgrammingType: number;
    public LstDefaultInsertionLength: DefaultInsertionLengthModel[];

    public constructor( data: any = {}) {
        this.IdPlayer = data.IdPlayer || '';
        this.CityCode = data.CityCode || 0;
        this.CityName = data.CityName || '';
        this.StandardMessage = data.StandardMessage || '';
        this.UseProxyServer = data.UseProxyServer || false;
        this.ProxyServerIP = data.ProxyServerIP || '';
        this.ProxyServerPort = data.ProxyServerPort || '';
        this.SendMessageStoppedPlayer = data.SendMessageStoppedPlayer || false;
        this.AcceptOfflineFiles = data.AcceptOfflineFiles || false;
        this.IdLiveUrl = data.IdLiveUrl || '';
        this.IdProgrammingType = data.IdProgrammingType || 0;
        this.LstDefaultInsertionLength = data.LstDefaultInsertionLength;
    }
}
