import { Guid } from 'app/services/guid';

export class NewContent {
    public tempId: string;
    public title: string;
    public text: string;
    public startDate: string;
    public endDate: string;
    public players: any[];
    public fileBytes: any;
    public fileName: string;

    public constructor(data: any = {}) {
        this.tempId = Guid.newGuid().replace('-', '_');
        this.title = data.title || '';
        this.text = data.text || '';
        this.startDate = data.startDate || '';
        this.endDate = data.endDate || '';
        this.players = data.players || [];
        this.fileBytes = data.fileBytes || '';
        this.fileName = data.fileName || '';
    }

    public loadUploadFile(file: any) {
        let instance = this;
        let reader = new FileReader();

        reader.onload = function(){
            instance.fileBytes = reader.result;

        };

        reader.readAsDataURL(file);
    }
}
