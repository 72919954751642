import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AdvertiserService, AdvertisingContractService, NotificationService, PlayerService } from '../../../services';
import { AdvertiserModel, AdvertisingContractModel, UserModel} from '../../../models';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { PageBaseComponent } from '../page-base.component';
import { DatePipe } from '@angular/common';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './anunciantes-contratos.component.html',
    styleUrls: ['./anunciantes-contratos.component.css'],
})
export class AnunciantesContratosComponent extends PageBaseComponent {

    public advertisers: AdvertiserModel[] = [];
    public advertiser: AdvertiserModel;
    public advertisingContracts: AdvertisingContractModel[] = [];
    public showLoading = true;
    public showContracts = false;
    public showLoadingContracts = true;
    public advertiserName = '';
    public showUserData = false;
    public ramosAtividade: any = [];
    public idiomas: any = [];
    public selectedItem = 4;
    public editing = false;
    public idAdvertiserSelected: string;
    public fileExtensionSelected: string;
    public newRelatedContract: any = {
        Title: '',
        InitialDate: '',
        EndDate: '',
        Duration: 15,
        FileContent: '',
        PlayersList : '',
        AdvertiserId: '',
        FileExtension: ''
    };
    public tiposValidos = '.jpg,.jpeg,.png,.mp4,.swf';
    public fileBytes: any;
    public addUser: any;
    public players: any[] = [];

    responseStatus: Object= [];

    constructor(private advertiserService: AdvertiserService, private playerService: PlayerService, private advertisingContractService: AdvertisingContractService, private notificationService: NotificationService) {
        super();
        this.loadData();
    }

    loadData(){
        this.advertiserService.getAllAssociatedToLoggedUser().subscribe(
            response => {
                this.advertisers = response;
                this.showLoading = false;
            },
            err => {
                if (err.status === 401 || err.status === 403) return;
                this.notificationService.showErrorMessage(err);
            }
        );

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;
                setTimeout(() => {
                    this.initChosen();
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        );
    }

    initChosen() {
        let instance = this;

        super.initChosen(function() {
            //
        });
        //instance.updateModel();
    }

    loadSelectedPlayers() {
        //$('#selectedPlayers').val(this.newUser.LstPlayer).trigger("chosen:updated.chosen");
       // selectedPlayers = this.newUser.LstPlayer;
       //$("#your-id").val("225").trigger("chosen:updated.chosen");
    }

    public ngOnInit() {
        let instance = this;
        this.initChosenSelect();
        this.initDatePickerWithCallback(function (date) {
            instance.updateModel();
        });

        this.advertiser = new AdvertiserModel();
        this.advertiser.User = new UserModel();
        this.ramosAtividade = [
            { id: 1, name: 'Outros' },
            { id: 2, name: 'Restaurantes' },
            { id: 3, name: 'Padarias' },
            { id: 4, name: 'Bancos' },
            { id: 5, name: 'Pessoa Física' },
            { id: 6, name: 'Lanchonete' },
            { id: 7, name: 'Imobiliarias' },
        ];
        this.idiomas = [
            { id: 'pt-BR', name: 'Português' },
            { id: 'en-US', name: 'Inglês' },
            { id: 'es-ES', name: 'Espanhol' },
        ];
    }

    openModalNovoAnunciante() {
        $('#modalNovoAnunciante').modal('show');
        $('.chosen-select').trigger('chosen:updated');
    }

    getAdvertisingContracts(advertiserId, advertiserName_par) {
        let instance = this;
        instance.showLoadingContracts = true;
        this.idAdvertiserSelected = advertiserId;
        this.advertiserName = advertiserName_par;
        this.advertisingContractService.getAllContracts(advertiserId).subscribe(
            response => {
                this.advertisingContracts = response;
                instance.showLoadingContracts = false;
                this.advertiserName = advertiserName_par;
            },
            err => {
                if (err.message) {
                    this.notificationService.showErrorNotification('', 'Erro ao buscar contratos: ' + err.Message);
                } else {
                    this.notificationService.showErrorNotification('', 'Erro ao buscar contratos do anunciante.');
                }
            }
        );
    }

    save(form: any, model: any, isValid: boolean)
    {

        if (!isValid) {
            return;
        }

        if (!this.showUserData){
            this.advertiser.User = null;
        }

        let cpf = this.advertiser.DocumentId.replace('.', '').replace('-', '');
        if (cpf.length > 11){
            this.advertiser.RegistrationNumber = this.advertiser.DocumentId;
            this.advertiser.DocumentId = '';
        }
        else{
            this.advertiser.DocumentId = this.advertiser.DocumentId;
            this.advertiser.RegistrationNumber = '';
        }

        if (!this.editing){
            this.advertiserService.saveRecord(this.advertiser).subscribe(
                response => {
                    this.notificationService.showSuccessNotification('', 'Anunciante adicionado com sucesso!');
                    $('#modalNovoAnunciante').modal('hide');
                    this.loadData();
                },
                err => {
                    if (err.message) {
                        this.notificationService.showErrorNotification('', 'Erro ao adicionar anunciante: ' + err.Message);
                    } else {
                        this.notificationService.showErrorNotification('', 'Erro ao adicionar anunciante.');
                    }
                }
            );
        }
        else{
            this.advertiserService.update(this.advertiser).subscribe(
                response => {
                    this.notificationService.showSuccessNotification('', 'Anunciante salvo com sucesso!');
                    $('#modalNovoAnunciante').modal('hide');
                    this.loadData();
                },
                err => {
                    if (err.message) {
                        this.notificationService.showErrorNotification('', 'Erro ao salvar anunciante: ' + err.Message);
                    } else {
                        this.notificationService.showErrorNotification('', 'Erro ao salvar anunciante.');
                    }
                }
            );
        }
    }

    saveNewRelatedContract(form: any, model: any, isValid: boolean) {
        if (!isValid) {
            return;
        }
        this.newRelatedContract.PlayersList = $('#selectedPlayers').val();
        this.newRelatedContract.FileContent = this.fileBytes;
        this.newRelatedContract.AdvertiserId = this.idAdvertiserSelected;
        this.newRelatedContract.FileExtension = this.fileExtensionSelected;
        this.advertisingContractService.sendContract(this.newRelatedContract)
        .subscribe(
            result => {
                console.log('ok');
                this.notificationService.showSuccessNotification('', 'Contrato enviado com sucesso!');
                $('#modalNovoContratoRelacionado').modal('hide');
                this.getAdvertisingContracts(this.advertiser.IdAdvertiser, this.advertiser.Name);
            },
            err => {
                console.log('err');
                this.notificationService.showErrorNotification('Não foi possível fazer o envio no momento!');
                $('#modalNovoContratoRelacionado').modal('hide');
                this.getAdvertisingContracts(this.advertiser.IdAdvertiser, this.advertiser.Name);
            }
        );

        this.updateModel();
    }

    delete(advertiserId) {
        let instance = this;

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover este anunciante?', 'warning', function() {

            instance.advertiserService.delete(advertiserId).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Anunciante removido com sucesso!');
                    instance.loadData();
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao remover anunciante!');
                }
            );

        }, function() {
            // Do nothing
        });
    }

    edit(advertiserId) {
        this.advertiserService.getDetail(advertiserId).subscribe(
            result => {
                this.advertiser = new AdvertiserModel(result);
                let cpf = this.advertiser.DocumentId.replace('.', '').replace('-', '');
                if (cpf.length == 0) cpf = this.advertiser.RegistrationNumber.replace('.', '').replace('-', '');
                if (cpf.length > 11){
                    this.advertiser.DocumentId = this.advertiser.RegistrationNumber;
                }
                else{
                    this.advertiser.DocumentId = this.advertiser.DocumentId;
                }
                this.editing = true;
                $('#modalNovoAnunciante').modal('show');
                $('.chosen-select').trigger('chosen:updated');
            },
            error => {
                this.notificationService.showErrorMessage(error);
            }
        );
        //this.notificationService.showErrorMessage('Não implementado ainda.');
    }

    changeCheckBox(){
        this.showUserData = !this.showUserData;

        if (this.showUserData){
            this.advertiser.User = new UserModel();
        }
        else{
            this.advertiser.User = null;
        }
    }

    initChosenSelect() {
        $('.chosen-select').chosen();

        $('.chosen-search').append('<i class="glyph-icon icon-search"></i>');
        $('.chosen-single div').html('<i class="glyph-icon icon-caret-down"></i>');
    }

    updateChosen() {
        $('.chosen-select').trigger('chosen:updated');
    }

    uploadFileChange(event: any = null) {
        let instance = this;
        let reader = new FileReader();

        reader.onload = function(){
            instance.fileBytes = reader.result;
        };
        for (let i = 0; i < event.target.files.length; i++) {
            //var newsContent = new NewsContentModel();
            //newsContent.loadUploadFile(event.target.files[i]);
            this.fileExtensionSelected = '.' + this.getFileExtension(event.target.files[i].name);
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    openFileUpload(id: any = null) {
        $('#' + id).click();
    }

    updateModel() {
        this.newRelatedContract.InitialDate = $('#initialDate').val();
        this.newRelatedContract.EndDate = $('#endDate').val();
    }

    showModalNovoContratoRelacionado() {
        this.newRelatedContract.InitialDate = this.getCurrentDate();
        this.newRelatedContract.EndDate = this.getCurrentDate();
        $('#modalNovoContratoRelacionado').modal('show');
    }

    public ngOnDestroy() {
    }

    ngAfterViewInit() {
    }
}
