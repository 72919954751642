export class InsertionRemoveBatch {
    public Type: number;
    public PlayerIdentificator: string;
    public Identificator: string;

    public constructor( data: any = {}) {
        this.Identificator = data.Identificator || '';
        this.PlayerIdentificator = data.PlayerIdentificator || '';
        this.Type = data.Type || 0;
    }
}
