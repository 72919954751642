export class UserPasswordRecoveryModel {
    public Identifier: string;
    public NewPass: string;
    public NewPassConfirm: string;

    constructor(data: any = {}) {
        this.Identifier = data.Identifier || '';
        this.NewPass = data.NewPass || '';
        this.NewPassConfirm = data.NewPassConfirm || '';
    }
}
