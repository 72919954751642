import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject.js';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotificationService {
  public current: ReplaySubject<any>;

  constructor(private notificationsService: NotificationsService) {
    this.current = new ReplaySubject(1);
  }

  public showMessage(title: string, message: string, type: string, func: Function = null, cancelFunc: Function = null) {
    this.current.next({
      title: title,
      message: message,
      type: type,
      func: func,
      cancelFunc: cancelFunc,
      options: {
        showCancelButton: false, confirmButtonColor: '#3085d6', confirmButtonText: 'Ok'
      }
    });
  }

  public showMessageWithRedirect(title: string, message: string, type: string, func: Function = null, cancelFunc: Function = null) {
    this.current.next({
      title: title,
      message: message,
      type: type,
      func: func,
      cancelFunc: cancelFunc,
      options: {
        showCancelButton: false, confirmButtonColor: '#3085d6', confirmButtonText: 'Ok'
      }
    });
  }

  public showConfirmationMessage(title: string, message: string, type: string, func: Function = null, cancelFunc: Function = null) {

    this.current.next({
      title: title,
      message: message,
      type: type,
      func: func,
      cancelFunc: cancelFunc,
      options: {
        showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Sim', cancelButtonText: 'Não'
      }
    });

  }

  public showErrorMessage(error: any) {
    if (error) {
      this.current.next({
        title: 'Erro',
        message: error.Message || 'Algo errado aconteceu, tente novamente!',
        type: 'error',
        func: null,
        options: {
          showCancelButton: false, confirmButtonColor: '#3085d6', confirmButtonText: 'Ok'
        }
      });
    }
  }

  public showSuccessNotification(title, content: any = null, timeOut = 3000) {
    const toast = this.notificationsService.success(title, content, {
      timeOut: timeOut,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true
    });
  }

  public showErrorNotification(title, content: any = null, timeOut = 3000) {
    const toast = this.notificationsService.error(title, content, {
      timeOut: timeOut,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true
    });
  }

  public showAlertNotification(title, content: any = null, timeOut = 3000) {
    const toast = this.notificationsService.alert(title, content, {
      timeOut: timeOut,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true
    });
  }

  // public showWarningNotification(title, content: any = null, timeOut = 3000) {
  //   const toast = this.notificationsService.warn(title, content, {
  //     timeOut: timeOut,
  //     showProgressBar: false,
  //     pauseOnHover: false,
  //     clickToClose: true
  //   });
  // }

  public showInfoNotification(title, content: any = null, timeOut = 3000) {
    const toast = this.notificationsService.info(title, content, {
      timeOut: timeOut,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true
    });
  }

  public showBareNotification(title, content: any = null, timeOut = 3000) {
    const toast = this.notificationsService.bare(title, content, {
      timeOut: timeOut,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true
    });
  }

}
