export class UserModel {
    public Name: string;
    public Email: string;
    public Logo: string;
    public Culture: string;
    public Evaluation: boolean;
    public Blocked: boolean;
    public FirstAccess: boolean;
    public DaysRemainingOfEvaluation: number;
    public Identificator: string;
    public TotalSpace: number;
    public UsedSpace: number;
    public Password: string;
    public LstPlayer: String[];

    public constructor( data: any = {}) {
        this.Name = data.Name || '';
        this.Email = data.Email || '';
        this.Logo = data.Logo || '';
        this.Culture = data.Culture || '';
        this.Evaluation = data.Evaluation || false;
        this.Blocked = data.Blocked || false;
        this.FirstAccess = data.FirstAccess || false;
        this.DaysRemainingOfEvaluation = data.DaysRemainingOfEvaluation || 0;
        this.Identificator = data.Identificator || '';
        this.TotalSpace = data.TotalSpace || 0;
        this.UsedSpace = data.UsedSpace || 0;
        this.Password = data.Password || '';
        this.LstPlayer = data.LstPlayer || '';
    }
}
