
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';
import { MonitorAttendanceFilterModel, MonitorAttendanceModel} from '../models';
import { BaseService } from './base.service';

@Injectable()
export class MonitorAttendanceService extends BaseService {

    constructor(public auth: AuthService, public config: Configuration, public http: Http) {
        super();
    }

    public getWithTotalizationByDay(filter: MonitorAttendanceFilterModel): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        return this.http.post(this.config.serverWithApiUrl + 'customer/monitorattendance/getservicetypetotalattendanceperdayreport/', filter, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getCompleteReport(filter: MonitorAttendanceFilterModel): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        return this.http.post(this.config.serverWithApiUrl + 'customer/monitorattendance/getcompletereport/', filter, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getPointOfServicesByPlayer(idPlayer: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/monitorattendance/getpointofservicesbyplayer/' + idPlayer, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public report(filter: MonitorAttendanceFilterModel): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.put(this.config.serverWithApiUrl + 'customer/monitorattendance/reportexcel/', filter, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getServiceTypesByPlayer(idPlayer: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/monitorattendance/getservicetypesbyplayer/' + idPlayer, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

}
