export class LiveUrlModel {
    public IdLiveUrl: string;
    public Url: string;
    public Active: boolean;
    public Description: string;

    public constructor( data: any = {}) {
        this.IdLiveUrl = data.IdLiveUrl || '';
        this.Url = data.Url || '';
        this.Active = data.UseProxyServer || false;
        this.Description = data.Description || '';
    }
}
