
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';

@Injectable()
export class OperationLogService {

    constructor(public auth: AuthService, public config: Configuration, public http: Http) {
    }

    public getByFilter(date: any, playerId: any, type: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/operationlog/getbyfilter/date/' + date + '/playerid/' + playerId + '/type/' + type, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

}
