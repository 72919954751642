import { Pipe, PipeTransform } from '@angular/core';
import { AppTranslateService } from 'app/services';
import { Alert } from 'selenium-webdriver';
@Pipe({
       name: 'secondsToTime'
})
export class SecondsToTimePipe {
times = {
    YEAR : 31557600,
    MONTH: 2629746,
    DAY: 86400,
    HOUR: 3600,
    MINUTE: 60,
    SECOND: 1
}

constructor(private translate: AppTranslateService) {

}

    transform(seconds){
        let time_string = '';
        let plural = '';
        for (let key in this.times){
            if (Math.floor(seconds / this.times[key]) > 0){
                if (Math.floor(seconds / this.times[key]) > 1 ){
                    plural = '(s)';
                }
                else{
                    plural = '';
                }

                let textTypeInterval = '';
                this.translate.get(['DEFAULT.' + key.toString()]).subscribe((res: string[]) => {
                    textTypeInterval = res['DEFAULT.' + key.toString()];
                });

                time_string += Math.floor(seconds / this.times[key]).toString() + ' ' + textTypeInterval + plural + ' ';
                seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);

            }
        }
        return time_string;
    }
}
