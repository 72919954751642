import { ContentBaseModel } from './content-base';
import { TablePriceProductModel } from 'app/models/table-price-product';

export class TablePriceContentModel extends ContentBaseModel {
    public Color: string;
    public AuxiliaryText: string;
    public LstTablePriceProduct: TablePriceProductModel[];
    public TypeOfInsertion: string;

    public constructor( data: any = {}) {
        super();
        this.Color = data.Color || '';
        this.AuxiliaryText = data.AuxiliaryText || '';
        this.LstTablePriceProduct = data.LstTablePriceProduct;
        this.TypeOfInsertion = data.TypeOfInsertion || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
        this.LstTablePriceProduct.length > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 &&
            this.TypeOfInsertion.length > 0 &&
            this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
