//our root app component
import {Component, Attribute} from '@angular/core'

@Component({
  selector: 'greet',
  template: `
      <span>{{hours | greet}}</span>
    `
})
export class Greet {
   public date;
   public hours;

  constructor() {
    this.date =  new Date();
    this.hours = this.date.getHours();
  }

}
