import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService, PlayerService, NotificationService } from '../../../services';
import { UserModel } from '../../../models';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { PageBaseComponent } from '../page-base.component';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent extends PageBaseComponent {

    public users: UserModel[] = [];
    public players: any[] = [];
    public showLoading = true;
    public editing = false;
    public newUser: UserModel;
    public idUserEditing = '';

    constructor(private userService: UserService, private playerService: PlayerService, private notificationService: NotificationService) {

        super();

        this.loadData();
    }

    public ngOnInit() {
        this.initChosen();
        this.newUser = new UserModel();
    }

    loadData() {
        this.userService.getAllAssocied().subscribe(
            response => {
                this.users = response;
               this.showLoading = false;
            },
            err => {
                this.notificationService.showMessage('', err.Message, 'error');
                this.showLoading = false;
            }
        )

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;
                setTimeout(() => {
                    this.initChosen();
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
        //this.newUser = new UserModel();
    }

    clickNovo(){
        this.editing = false;
        this.newUser = new UserModel();
        $('#selectedPlayers').val(this.newUser.LstPlayer).trigger('chosen:updated.chosen');
        this.openModalNovoUsuario();
    }

    openModalNovoUsuario() {
        $('#modalNovoUsuario').modal('show');
        $('.chosen-select').trigger('chosen:updated');
    }

    initChosen() {
        let instance = this;

        super.initChosen(function() {
            //
        });
        //instance.updateModel();
    }

    updateModel() {
        $('#selectedPlayers').val(this.newUser.LstPlayer).trigger('chosen:updated.chosen');
       // selectedPlayers = this.newUser.LstPlayer;
       //$("#your-id").val("225").trigger("chosen:updated.chosen");
    }

    save(form: any, model: any, isValid: boolean) {

        if (!isValid) {
            return;
        }

        let newModel = {
            'LstPlayer': $('#selectedPlayers').val(),
            'Name': this.newUser.Name,
            'Email': this.newUser.Email,
            'Password': this.newUser.Password,
            'Culture': $('#selectedCulture').val(),
            'Blocked': $('#newUserBlock').is(':checked') ? 'true' : 'false',
            'Identificator': this.idUserEditing
        };

        if (!this.editing) {
            this.userService.add(newModel).subscribe(
                response => {
                    this.notificationService.showSuccessNotification('', 'Usuário adicionado com sucesso!');
                    $('#modalNovoUsuario').modal('hide');
                    this.loadData();
                },
                err => {
                    if (err.Message) {
                        this.notificationService.showErrorNotification('', 'Erro ao adicionar usuário: ' + err.Message);
                    } else {
                        this.notificationService.showErrorNotification('', 'Erro ao adicionar usuário.');
                    }
                }
            );
        }

        else {
            this.userService.update(newModel).subscribe(
                response => {
                    this.notificationService.showSuccessNotification('', 'Usuário salvo com sucesso!');
                    $('#modalNovoUsuario').modal('hide');
                    this.loadData();
                },
                err => {
                    if (err.Message) {
                        this.notificationService.showErrorNotification('', 'Erro ao editar usuário: ' + err.Message);
                    } else {
                        this.notificationService.showErrorNotification('', 'Erro ao editar usuário.');
                    }
                }
            );
        }
    }

    removeUser(userId) {
        let instance = this;

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover este usuário?', 'warning', function() {

            instance.userService.delete(userId).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Usuário removido com sucesso!');
                    instance.loadData();
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao adicionar usuário!');
                }
            );

        }, function() {
            // Do nothing
        });
    }

    edit(userId) {
        let instance = this;
        this.idUserEditing = userId;
        instance.userService.getDetailsToEdit(userId).subscribe(
            result => {
                instance.newUser = new UserModel(result);
                instance.editing = true;
                instance.updateModel();
                this.initChosen();

                instance.openModalNovoUsuario();
            },
            error => {
                instance.notificationService.showErrorMessage(error);
            }
        );
    }

    public ngOnDestroy() {
    }
}
