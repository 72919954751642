export class TablePriceProductModel {
    public ProductId: number;
    public Sequence: number;
    public Description: string;
    public Value: string;
    public Unity: string;

    public constructor( data: any = {}) {
        this.ProductId = data.ProductId || 0;
        this.Sequence = data.Sequence || 0;
        this.Description = data.Description || '';
        this.Value = data.Value || '';
        this.Unity = data.Unity || '';
    }

    //public isValid(): boolean {
        //return this.Title.length > 0 && this.InitialDate.length > 0 &&
            //this.OfferedBy.length > 0 && this.Requirement.length > 0 &&
            //this.Salary > 0 && this.Phone.length > 0 &&
            //this.EndDate.length > 0 && this.FileContent.length > 0 &&
            //this.PlayersList.length > 0 && this.FileExtension.length > 0;
    //}
}
