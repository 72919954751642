export class PlayerLicenseInfoModel {
    public Lisence: string;
    public PlayerName: string;
    public LisenceType: string;
    public ActivationDate: string;
    public YourVersion: string;
    public CurrentVersion: string;
    public LogoUrl: string;
    public LogoHCUrl: string;

    public constructor( data: any = {}) {
        this.Lisence = data.Lisence || '';
        this.PlayerName = data.PlayerName || '';
        this.LisenceType = data.LisenceType || '';
        this.ActivationDate = data.ActivationDate || '';
        this.YourVersion = data.YourVersion || ' - ';
        this.CurrentVersion = data.CurrentVersion || ' - ';
        this.LogoUrl = data.LogoUrl || '';
        this.LogoHCUrl = data.LogoHCUrl || '';
    }
}
