import { RSSContentChannelModel } from 'app/models';

export class RSSFontModel {
    public IdRssFont: string;
    public Description: string;
    public LstRssContentChannel: RSSContentChannelModel [];

    public constructor( data: any = {}) {
        this.IdRssFont = data.IdLiveUrl || '';
        this.Description = data.Description || '';
        this.LstRssContentChannel = data.LstRssContentChannel;
    }
}
