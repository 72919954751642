import { NgZone, Renderer, ElementRef, ViewChild, Component, Optional, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services';
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
  } from '@angular/router'
  import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import { Configuration } from '../app.constants';

  declare var jquery: any;
  declare var $: any;

@Component({
    selector: 'app',
    templateUrl: './secure.component.html',
    styleUrls: ['./secure.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SecureComponent {

    public isLoading: any = true;
    public version: any;

    constructor(private router: Router,
        private ngZone: NgZone,
        private renderer: Renderer,
        public auth: AuthService,
        private slimLoadingBarService: SlimLoadingBarService,
        private configuration: Configuration) {
        router.events.subscribe((event: RouterEvent) => {
            this._navigationInterceptor(event)
          });
    }

    private _navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.isLoading = true;
            if ($('#mobile-navigation').is(':visible')){
                $('#mobile-navigation #nav-toggle').click();
            }

            this.slimLoadingBarService.start();
        }

        if (event instanceof NavigationEnd) {
            this.isLoading = false;
            this.slimLoadingBarService.complete();
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.isLoading = false;
            this.slimLoadingBarService.stop();
        }
        if (event instanceof NavigationError) {
            this.isLoading = false;
            this.slimLoadingBarService.stop();
        }
      }

    public ngOnInit() {
        this.version = this.configuration.version;
        this.auth.getMe().subscribe(
                result => {
                    this.isLoading = false;
                    // alert('Seja bem vindo ' + this.auth.getUserData());
                },
                err => {
                    this.auth.logout();
                    this.router.navigate(['/login'], { queryParams: { expired: 1 }});
                }
        )
    }
}
