import { Guid } from 'app/services/guid';

export class RSSNewsRestrictionModel {
    public IdRssNewsRestriction: string;
    public RestrictedWord: string;
    public TempId: string;

    public constructor( data: any = {}) {
        this.IdRssNewsRestriction = data.IdRssNewsRestriction || '';
        this.RestrictedWord = data.RestrictedWord || '';
        this.TempId = Guid.newGuid().replace('-', '_');
    }
}
