import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService, NotificationApiService, PlayerService, NotificationService, WarningApiService } from '../../../services';
import { UserModel, PlayerModel } from '../../../models';
import { inspect } from 'util';
import { AgmMap } from '@agm/core/directives/map';
import { LatLngBounds, MapsAPILoader } from '@agm/core';
import { $ } from 'protractor';
import { marker } from './../../models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  public userInfo: UserModel = new UserModel();
  public notifications: any[] = [];
  public playersNotifications: any[] = [];
  public players: PlayerModel[] = [];
  public warnings: any[] = [];

  public showLoadingMap = true;
  public showLoadingNotifications = true;
  public showLoadingWarnings = true;
  // google maps zoom level
  zoom = 4;

  public markers: marker[] = [
  ];

  // initial center position for the map
  public lat = 51.673858;
  public lng = 7.815982;

  @ViewChild(AgmMap) public agmMap: AgmMap;

  public fitBounds: LatLngBounds;

  constructor(private authService: AuthService, private notificationApiService: NotificationApiService, private playerService: PlayerService,
    private notificationService: NotificationService, private mapsAPILoader: MapsAPILoader, private warningApiService: WarningApiService) {

    let instance = this;

    this.playerService.getAllLocations().subscribe(
      response => {
        this.players = response;

        this.players.forEach(function (item) {
          instance.markers.push({
            Identificator: item.Identificator,
            label: item.Name,
            lat: item.Latitude,
            lng: item.Longitude,
            draggable: false
          });
        });

        this.showLoadingMap = false;

        this.mapsAPILoader.load().then(() => {
          instance.fitBounds = new window['google'].maps.LatLngBounds();
          instance.agmMap.fitBounds = instance.fitBounds;
          this.players.forEach(function (item) {
            instance.fitBounds.extend(new window['google'].maps.LatLng(item.Latitude, item.Longitude))
          });

          instance.agmMap.triggerResize(true);
        });
      },
      error => {
        this.showLoadingMap = false;
        if (error.status === 401 || error.status === 403) return;
        this.notificationService.showErrorMessage(error);
      }
    );
  }
  public ngOnInit() {
    this.userInfo = new UserModel(this.authService.getUserData());
    this.loadNotifications();
    this.loadWarnings();
  }

  public loadNotifications() {
    this.notificationApiService.getLastAssocied().subscribe(
      response => {
        this.notifications = response;
        this.showLoadingNotifications = false;
      },
      error => {
        this.showLoadingNotifications = false;
        if (error.status === 401 || error.status === 403) return;
        this.notificationService.showErrorMessage(error);
      }
    )
  }

  public loadWarnings() {
    this.warningApiService.getAllAssocied().subscribe(
      response => {
        this.warnings = response;
        this.showLoadingWarnings = false;
      },
      error => {
        this.showLoadingWarnings = false;
        if (error.status === 401 || error.status === 403) return;
        this.notificationService.showErrorMessage(error);
      }
    )
  }

  public ngOnDestroy() {
    // removing the header
    //this.breadServ.clear();
  }

  ngAfterViewInit() {

  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  markedAsRead(identificator: string) {

    this.showLoadingNotifications = true;
    this.notificationApiService.markAsReaded(identificator).subscribe(
      response => {
        this.loadNotifications();
        this.notificationService.showSuccessNotification('Notificação removida');
      },
      error => {
        this.notificationService.showMessage('', error.Message, 'error');
        this.showLoadingNotifications = false;
      }
    )
  }

  markedWarningAsRead(identificator: string) {

    this.showLoadingWarnings = true;
    this.warningApiService.markAsReaded(identificator).subscribe(
      response => {
        this.loadWarnings();
        this.notificationService.showSuccessNotification('Notificação removida');
      },
      error => {
        this.notificationService.showMessage('', error.Message, 'error');
        this.showLoadingWarnings = false;
      }
    )
  }

  mapClicked($event: any) {
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
}
