import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService, PlayerService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { NewContent } from 'app/components/models';
import { Input } from '@angular/core';
import { AdvertiserModel, AdvertisingContractModel } from '../../../models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'envio-conteudo-contrato',
    templateUrl: './envio-conteudo-contrato.component.html',
    styleUrls: ['./envio-conteudo-contrato.component.css'],
})
export class EnvioConteudoContratoComponent extends PageBaseComponent {

    public players: any[] = [];
    public hasSelectedFiles = false;

    @Input('ngConteudo')
    public conteudo: AdvertisingContractModel = new AdvertisingContractModel();

    constructor(private notificationService: NotificationService, private playerService: PlayerService) {

        super();

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;
                this.initChosen(function() {

                });
            }, err => {
                if (err.status === 401 || err.status === 403) return;
                this.notificationService.showErrorMessage(err);
            }
        )
    }




    public ngOnInit() {

    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initChosen(function() {

        });
        this.initDatePicker();

        this.conteudo.InitialDate = this.getCurrentDate();
        this.conteudo.EndDate = this.getCurrentDate();
    }

    uploadFileSelected(event: any) {
        console.log(event);
        this.hasSelectedFiles = event.target.files.length > 0;
    }

    uploadFileChange(event: any = null) {
        let instance = this;
        let reader = new FileReader();

        reader.onload = function(){
            instance.conteudo.FileContent = reader.result as string;
        };
        this.conteudo.FileExtension = '.' + this.getFileExtension(event.target.files[0].name);
        reader.readAsDataURL(event.target.files[0]);
    }

    openFileUpload(id: any = null) {
        $('#' + id).click();
    }
}
