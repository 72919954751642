import { Component } from '@angular/core';
import { AppTranslateService, NotificationService, AuthService } from './services';
import { ViewChild } from '@angular/core';
import { Configuration } from './app.constants';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

declare var tinymce: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'app';
  @ViewChild('dialog') public swalDialog: SwalComponent;

  public swalTitle = 'title';
  public swalText = 'text';
  public swalType = 'info';

  constructor(private translate: AppTranslateService,
    private notificationService: NotificationService,
    public authService: AuthService,
    private config: Configuration) {

    this.notificationService.current.subscribe((data) => setTimeout(() => {

      this.showMessage(data.title || '', data.message || '', data.type || '', data.func || null, data.cancelFunc || null, data.options || {});

    }, 0));

  }

  public ngOnInit() {

  }

  ngAfterViewInit() {
    tinymce.baseURL = 'assets';
  }

  private showMessage(title, message, type, func: Function, cancelFunc: Function, options) {

    // this.swalDialog.title = title;
    // this.swalDialog.text = message;
    // this.swalDialog.type = type;

    this.swalTitle = title;
    this.swalText = message;
    this.swalType = type;

    setTimeout(() => {
        this.swalDialog.options = options;
        this.swalDialog.show().then(function(value: any) {
            if (value.value && func) {
                func();
            }
        }, function(reason: any) {
            console.log(reason);
            cancelFunc();
        }).catch(error => {
            if (cancelFunc) {
                cancelFunc();
            }
        }); ;
    });
  }

}
