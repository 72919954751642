import { InsertionTypeModel } from 'app/models/insertion-type';

export class DefaultInsertionLengthModel {
    public InsertionType: InsertionTypeModel;
    public Duration: number;
    public DoNotLimitVideoFiles: boolean;

    public constructor( data: any = {}) {
        this.InsertionType = data.InsertionType || '';
        this.Duration = data.Duration || 0;
        this.DoNotLimitVideoFiles = data.DoNotLimitVideoFiles || false;
    }
}
