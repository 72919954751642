import { ContentBaseModel } from './content-base';

export class YoutubeVideoContentModel extends ContentBaseModel {
    public UrlVideo: string;

    public constructor( data: any = {}) {
        super();
        this.UrlVideo = data.UrlVideo || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.UrlVideo.length > 0 &&
            this.EndDate.length > 0 &&
            this.PlayersList.length > 0;
    }
}
