import {Pipe} from '@angular/core';

@Pipe({
    name: 'documentCpf'
})
export class DocumentPipe {
    transform(val, args) {
        const mask = '999.999.999-99';
        let valor = val.replace(/\D/g, '');
        const pad = mask.replace(/\D/g, '').replace(/9/g, '_');
        const valorMask = valor + pad.substring(0, pad.length - valor.length);
        let valorMaskPos = 0;

        valor = '';
        for (let i = 0; i < mask.length; i++) {
        if (isNaN(parseInt(mask.charAt(i)))) {
            valor += mask.charAt(i);
        } else {
            valor += valorMask[valorMaskPos++];
        }
        }

        if (valor.indexOf('_') > -1) {
        valor = valor.substr(0, valor.indexOf('_'));
        }

        return valor;
    }
}
