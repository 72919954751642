import { PlayerModel } from 'app/models';
import { Guid } from 'app/services/guid';

export class ContentBaseModel {
    public TempId: string;
    public IdContent: string;
    public Title: string;
    public InitialDate: string;
    public EndDate: string;
    public CreatedOn: Date;
    public FileContent: String;
    public PlayersList: String[];
    public FileExtension: string;
    public FileName: string;

    public constructor( data: any = {}) {
        this.TempId = Guid.newGuid();
        this.IdContent = data.IdContent || '';
        this.Title = data.Title || '';
        this.InitialDate = data.InitialDate || '';
        this.EndDate = data.EndDate || '';
        this.CreatedOn = data.CreatedOn || '';
        this.FileContent = data.FileContent || '';
        this.PlayersList = data.SelectedPlayers || [];
        this.FileExtension = data.FileExtension || '';
        this.FileName = data.FileName || '';
    }

    public loadUploadFile(file: any) {
        const instance = this;
        const reader = new FileReader();

        reader.onload = function(){
            instance.FileContent = reader.result as String;
        };

        reader.readAsDataURL(file);
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 && this.EndDate.length > 0 && this.FileContent.length > 0 && this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
