import { ContentBaseModel } from './content-base';

export class OffersContentModel extends ContentBaseModel {
    public Price: number;
    public TypeOfInsertion: string;
    public TypeOfUnity: string;
    public constructor( data: any = {}) {
        super();
        this.Price = data.Price || '';
        this.TypeOfInsertion = data.TypeOfInsertion || '';
        this.TypeOfUnity = data.TypeOfUnity || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 &&
            this.TypeOfInsertion.length > 0 &&
            this.TypeOfUnity.length > 0 &&
            this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
