
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';
import { NewsContentModel, AdvertisingContractModel, OffersContentModel, ImageContentModel, MultimediaContentModel, JobOfferContentModel,
    TablePriceContentModel, HtmlContentModel, YoutubeVideoContentModel } from '../models';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CentralContentService {
  constructor(
    public auth: AuthService,
    public config: Configuration,
    public http: Http,
    public httpClient: HttpClient
  ) {}

  // public model: NewsContentModel;

  public sendNewsContent(files: NewsContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl + 'customer/centralcontent/sendnewscontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendOffersContent(files: OffersContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };
console.log(files);
    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendofferscontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendImageContent(files: ImageContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendimagecontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendMultimediaContent(
    files: MultimediaContentModel[]
  ): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendmultimediacontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendJobOfferContent(files: JobOfferContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendjoboffercontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendTablePriceContent(
    files: TablePriceContentModel[]
  ): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendpricetablecontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendHtmlContent(files: HtmlContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl + 'customer/centralcontent/sendhtmlcontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendYoutubeVideoContent(
    files: YoutubeVideoContentModel[]
  ): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl +
        'customer/centralcontent/sendyoutubevideocontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendTipContent(files: NewsContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl + 'customer/centralcontent/sendtipcontent/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public sendWebPage(files: NewsContentModel[]): Observable<any> {
    // add authorization header with jwt token
    const headers = {
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    };

    return this.httpClient.post(
      this.config.serverWithApiUrl + 'customer/centralcontent/sendwebpage/',
      files,
      { headers: headers, reportProgress: true, observe: 'events' }
    );
  }

  public getMyContent(): Observable<any> {
    // add authorization header with jwt token
    const headers = new Headers({
      Authorization: 'Bearer ' + this.auth.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': 'pt-BR'
    });
    const options = new RequestOptions({ headers: headers });
    const data = {};

    return this.http
      .get(
        this.config.serverWithApiUrl + 'customer/centralcontent/getmycontent',
        options
      )
      .pipe(
        map((response: Response) => {
          return response.json();
        }),
        catchError((error: any) => {
          return observableThrowError(
            error.json() || error.json().error || 'Server error'
          );
        })
      );
  }
}
