import { ContentBaseModel } from './content-base';

export class JobOfferContentModel extends ContentBaseModel {
    public OfferedBy: string;
    public Requirement: string;
    public Salary: string;
    public Phone: string;

    public constructor( data: any = {}) {
        super();
        this.OfferedBy = data.OfferedBy || '';
        this.Requirement = data.Requirement || '';
        this.Salary = data.Salary || '';
        this.Phone = data.Phone || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.OfferedBy.length > 0 && this.Requirement.length > 0 &&
            this.Salary.length > 0 && this.Phone.length > 0 &&
            this.EndDate.length > 0 &&
            this.PlayersList.length > 0;
    }
}
