export class PlayerModel {
    public Identificator: string;
    public SerialNumber: string;
    public Name: string;
    public Version: string;
    public Blocked: boolean;
    public IsOnline: boolean;
    public UsedSpace: number;
    public FreeSpace: number;
    public Latitude: number;
    public Longitude: number;
    public DisplayingNowImage: string;
    public DisplayingNowInsertionName: string;
    public UpTime: number;

    public constructor( data: any = {}) {
        this.Identificator = data.Identificator || '';
        this.SerialNumber = data.SerialNumber || '';
        this.Name = data.Name || '';
        this.Version = data.Version || '';
        this.Blocked = data.Blocked || false;
        this.IsOnline = data.IsOnline || false;
        this.UsedSpace = data.UsedSpace || 0;
        this.FreeSpace = data.FreeSpace || 0;
        this.Latitude = data.Latitude || 0;
        this.Longitude = data.Longitude || 0;
        this.DisplayingNowImage = data.DisplayingNowImage || '';
        this.DisplayingNowInsertionName = data.DisplayingNowInsertionName || '';
        this.UpTime = data.UpTime || 0;
    }
}
