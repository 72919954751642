export class OccurrenceNoteModel {
    public identificator: string;
    public title: string;
    public text: string;

    public constructor( data: any = {}) {
        this.identificator = data.identificator || '';
        this.title = data.title || '';
        this.text = data.text || '';
    }
}
