import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MenuItem } from '../../models';
import { OccurrenceService, NotificationService } from '../../../services';
import { OccurrenceModel } from 'app/models/occorrence';
import { PageBaseComponent } from 'app/components/pages/page-base.component';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './ocorrencias-suporte.component.html',
    styleUrls: ['./ocorrencias-suporte.component.css'],
})
export class OcorrenciasSuporteComponent extends PageBaseComponent  {

    public versoesIpainel: MenuItem[] = [];
    public versoesClientes: MenuItem[] = [];
    public softwaresUteis: MenuItem[] = [];
    public manuaisDeUsuario: MenuItem[] = [];
    public showLoadingOpenedOccurrences = true;
    public showLoadingClosedOccurrences = true;

    public openedOccurrences: any[] = [];
    public closedOccurrences: any[] = [];

    public occurrenceData: OccurrenceModel = new OccurrenceModel({
        title: '',
        text: ''
    });

    constructor(private occurrenceService: OccurrenceService, private notificationService: NotificationService) {
        super();
    }

    public ngOnInit() {
        this.loadLinks();
        this.loadOpenedOccurrences();
        this.loadClosedOccurrences();
    }

    public loadLinks() {
        this.manuaisDeUsuario.push((new MenuItem({ title: 'iPainel Player - Manual do Usuário', link: 'http://download.ipainel.tv/Manual.pdf' })));

        this.versoesIpainel.push(new MenuItem({ title: 'Player iPainel: Instalador (Win 32/64)', link: 'http://download.ipainel.tv/Instalar_iPainel_Player.exe' }));
        this.versoesIpainel.push(new MenuItem({ title: 'Executável iPainel (Versão Atualizada)', link: 'http://download.ipainel.tv/iPainel.exe' }));
        this.softwaresUteis.push(new MenuItem({ title: 'Smart: DLLs Impressora, executáveis e script', link: 'http://download.ipainel.tv/smart/pacote_smart.zip' }));
        this.versoesClientes.push(new MenuItem({ title: 'Smart: Ponto de atendimento', link: 'http://download.ipainel.tv/smart/iPainel_Smart_Ponto_Atendimento.exe' }));
        this.versoesClientes.push(new MenuItem({ title: 'Smart: Toten', link: 'http://download.ipainel.tv/smart/iPainel_Smart_Toten.exe' }));

        this.softwaresUteis.push(new MenuItem({ title: '.NET Framework 2.0 (necessário para execução do iPainel)', link: 'http://download.ipainel.tv/Instala_Framework_2.0.exe' }));
        this.softwaresUteis.push(new MenuItem({ title: 'Flash Player (Necessário para execução do conteúdo)', link: 'http://get.adobe.com/br/flashplayer/download/?installer=Flash_Player_11_for_Other_Browsers&os=Windows%207&browser_type=Gecko&browser_dist=Firefox&d=McAfee_Security_Scan_Plus_FireFox_Browser&dualoffer=false' }));
        this.versoesClientes.push(new MenuItem({ title: 'Cliente iPainel Guichê por PC', link: 'http://download.ipainel.tv/ClienteIP_Guiche.zip' }));
        this.versoesClientes.push(new MenuItem({ title: 'Cliente iPainel Senha/Guichê por PC', link: 'http://download.ipainel.tv/ClienteIP_SenhaGuiche.zip' }));
        this.softwaresUteis.push(new MenuItem({ title: 'Captura controles Rádio Frequência', link: 'http://download.ipainel.tv/CapturaControle.exe' }));
        this.softwaresUteis.push(new MenuItem({ title: 'Gravações de áudio (senha / Guichê)', link: 'http://download.ipainel.tv/Audios.zip' }));
        this.softwaresUteis.push(new MenuItem({ title: 'RestartOnCrash (Utilitário)', link: 'http://download.ipainel.tv/RestartOnCrash.exe' }));
    }

    public loadOpenedOccurrences() {
        this.occurrenceService.getAllAssocied(true).subscribe(
            response => {
                this.openedOccurrences = response;
                this.showLoadingOpenedOccurrences = false;
            },
            error => {
                this.showLoadingOpenedOccurrences = false;
                this.notificationService.showMessage('', error.Message, 'error');
            }
        )
    }

    public loadClosedOccurrences() {
        this.occurrenceService.getAllAssocied(false).subscribe(
            response => {
                this.closedOccurrences = response;
                this.showLoadingClosedOccurrences = false;
            },
            error => {
                this.showLoadingClosedOccurrences = false;
                this.notificationService.showMessage('', error.Message, 'error');
            }
        )
    }

    public add(form: any, model: any, isValid: boolean) {

        if (!isValid) {
            return;
        }

        this.showLoadingOpenedOccurrences = true;

        this.occurrenceService.add(model).subscribe(
            response => {
                this.clearAndDismissModal();
                this.notificationService.showSuccessNotification('Ocorrência adicionada');
                this.loadOpenedOccurrences();
            },
            error => {
                this.clearAndDismissModal();
                this.showLoadingOpenedOccurrences = false;
                this.notificationService.showMessage('', error.Message, 'error');
            }
        )
    }

    public clearAndDismissModal() {

        $('#title').val('');
        $('#text').val('');
        $('#modalNovaOcorrencia').modal('hide');
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {

    }
}
