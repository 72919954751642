import { ContentBaseModel } from './content-base';

export class TipContentModel extends ContentBaseModel {
    public Text: string;

    public constructor( data: any = {}) {
        super();
        this.Text = data.Text || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.Text.length > 0 && this.InitialDate.length > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 && this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
