export class UserPasswordModel {
    public CurrentPassword: string;
    public NewPass: string;
    public NewPassConfirm: string;

    constructor(data: any = {}) {
        this.CurrentPassword = data.CurrentPassword || '';
        this.NewPass = data.NewPass || '';
        this.NewPassConfirm = data.NewPassConfirm || '';
    }
}
