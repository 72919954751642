export class InsertionTypeModel {
    public InsertionTypeId: string;
    public Description: string;
    public Icon: string;

    public constructor( data: any = {}) {
        this.InsertionTypeId = data.InsertionTypeId || '';
        this.Description = data.Description || '';
        this.Icon = data.Icon || '';
    }
}
