
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';


import { Router } from '@angular/router';
import { NotificationService } from 'app/services';


@Injectable()
export class AuthenticatedHttpService extends Http {

  constructor(backend: XHRBackend, defaultOptions: RequestOptions, private router: Router) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).pipe(catchError((error: Response) => {
            if ((error.status === 401 || error.status === 403) && (window.location.href.match(/\?/g) || []).length < 2) {
                console.log('The authentication session expires or the user is not authorised. Force refresh of the current page.');
                if (localStorage.getItem('authorizationData') != undefined) {
                  localStorage.removeItem('authorizationData');
                  this.router.navigate(['/login'], { queryParams: { expired: 1 }});
                  return observableThrowError(error);
                } else {
                  localStorage.removeItem('authorizationData');
                  this.router.navigate(['/login'], { queryParams: { expired: 0 }});
                  return observableThrowError(error);
                }
            }
            return observableThrowError(error);
        }));
    }
}
