
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';
import { UserPasswordRecoveryModel } from '../models';

@Injectable()
export class UserService {

    constructor(public auth: AuthService, public config: Configuration, public http: Http) {
    }

    public changePassword(details: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = details;

        return this.http.put(this.config.serverWithApiUrl + 'customer/user/changepassword', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public sendmailrecoverypassword(email: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = email;

        return this.http.put(this.config.serverWithApiUrl + 'customer/user/sendmailrecoverypassword?email=' + email, null, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getDetailsToEdit(userId: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });

        const id = userId.replace('/', '__');
        return this.http.get(this.config.serverWithApiUrl + 'customer/user/getdetailstoedit/' + id, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getAllAssocied(): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/user/getallassociated', options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public add(model: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = model;

        return this.http.post(this.config.serverWithApiUrl + 'customer/user/add', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public delete(userId: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });

        return this.http.delete(this.config.serverWithApiUrl + 'customer/user/delete/' + userId, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public update(model: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = model;

        return this.http.put(this.config.serverWithApiUrl + 'customer/user/update', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public block(model: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = model;

        return this.http.put(this.config.serverWithApiUrl + 'customer/user/block', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public recoverypassword(details: UserPasswordRecoveryModel): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = details;

        return this.http.put(this.config.serverWithApiUrl + 'customer/user/recoverypassword', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }
}
