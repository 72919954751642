import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
// import { SlimScroll } from 'angular-io-slimscroll';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap';
import { AppComponent } from './app.component';
import { PublicComponent, SecureComponent } from './layouts'
import { HomeComponent, LoginComponent, HeaderComponent, SidebarComponent, Greet, Error500Component,
    FaturamentoComponent, MeusPaineisComponent, MeusPaineisDetailsComponent, StatusServicosComponent,
    OcorrenciasSuporteComponent, OcorrenciasSuporteDetailsComponent, UsuariosComponent, LogOperacionalComponent, CentralDeConteudoComponent,
    VisualizadorDeExecucaoComponent, AnunciantesContratosComponent, AtendimentosComponent, ProgramacaoComponent,
    RecuperarSenhaComponent,
    NotificacoesComponent, CampanhaComponent,
    EnvioDeConteudoComponent, EnvioConteudoImagemComponent, EnvioConteudoNoticiaComponent, EnvioConteudoMultimidiaComponent,
    AlertasComponent, EnvioConteudoAvisoEmpregoComponent, EnvioConteudoContratoComponent, EnvioConteudoHtmlComponent,
    EnvioConteudoOfertasComponent, EnvioConteudoTabelaPrecoComponent, EnvioConteudoYoutubeComponent, EnvioConteudoDicaComponent, 
    RemocaoEmLoteComponent, EnvioConteudoWebPageComponent
} from './components';

import { AuthService, AuthenticatedHttpService, CanActivateGuard, AppTranslateService, UserService,
    NotificationService, PlayerService, AdvertiserService, AdvertisingContractService, BillingService, OccurrenceService,
    OperationLogService, ServiceStatusService, NotificationApiService, CollectionFreeContentService, CentralContentService,
    ViewerExecutionApiService, WarningApiService, MockDataService, RegisterService, ConfigurationService, MonitorAttendanceService,
    ProgrammingApiService, RemoteCommandApiService,
} from './services';

import { routing } from './app.routes';

import { Http } from '@angular/http';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Configuration } from './app.constants';

import { AgmCoreModule } from '@agm/core';
import { GridExemploComponent } from 'app/components/pages/grid-exemplo/grid-exemplo.component';
import { QuickAccessComponent } from 'app/components/widgets/quick-access';

import { SharedModule } from './shared/shared.module';
import { DatePipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { SimpleTinyComponent } from './components/widgets/tinymce.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const services = [AuthenticatedHttpService, AuthService, CanActivateGuard, AppTranslateService, UserService,
    NotificationService, PlayerService, AdvertiserService, AdvertisingContractService, BillingService, OccurrenceService,
    OperationLogService, ServiceStatusService, NotificationApiService, CollectionFreeContentService, CentralContentService,
    ViewerExecutionApiService, WarningApiService, MockDataService, ConfigurationService, RegisterService, MonitorAttendanceService,
    ProgrammingApiService, RemoteCommandApiService];

@NgModule({
  declarations: [
    AppComponent, PublicComponent, SecureComponent, HomeComponent, LoginComponent, Error500Component,
    HeaderComponent, SidebarComponent, FaturamentoComponent, MeusPaineisComponent, MeusPaineisDetailsComponent,
    StatusServicosComponent, OcorrenciasSuporteComponent, OcorrenciasSuporteDetailsComponent, UsuariosComponent, LogOperacionalComponent,
    CentralDeConteudoComponent, VisualizadorDeExecucaoComponent, AnunciantesContratosComponent, AtendimentosComponent,
    ProgramacaoComponent, CampanhaComponent, RecuperarSenhaComponent,  EnvioDeConteudoComponent, EnvioConteudoImagemComponent, 
    RemocaoEmLoteComponent, EnvioConteudoWebPageComponent,
    EnvioConteudoNoticiaComponent, EnvioConteudoMultimidiaComponent, EnvioConteudoAvisoEmpregoComponent, EnvioConteudoContratoComponent,
    EnvioConteudoHtmlComponent, EnvioConteudoOfertasComponent, EnvioConteudoTabelaPrecoComponent, EnvioConteudoYoutubeComponent,
    EnvioConteudoDicaComponent, NotificacoesComponent, AlertasComponent, GridExemploComponent, QuickAccessComponent,
    Greet,
    // SlimScroll,
    SimpleTinyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    routing,
    FormsModule,
    SweetAlert2Module.forRoot(),
    SlimLoadingBarModule.forRoot(),
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyAh3x8HAEH9yIhChsUIssI2chlqLatWKEs'
    }),
    SimpleNotificationsModule.forRoot(),
    PaginationModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ChartsModule
  ],
  providers: [...services, Configuration, { provide: Http, useClass: AuthenticatedHttpService }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
