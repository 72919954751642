export class MonitorAttendanceFilterModel {
    public IdPlayer: string;
    public InitialDate: string;
    public EndDate: string;
    public IdPointOfService: string;
    public ServiceType: string;

    public constructor( data: any = {}) {
        this.IdPlayer = data.IdPlayer || '';
        this.InitialDate = data.InitialDate || '';
        this.EndDate = data.EndDate || '';
        this.IdPointOfService = data.IdPointOfService || '';
        this.ServiceType = data.ServiceType || '';
    }
}
