import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService, ProgrammingApiService } from 'app/services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
// import { setTimeout } from 'timers';
import { PlayerProgrammingItemModel, NewProgrammingModel } from '../../../models';
import { InsertionTypeModel } from 'app/models/insertion-type';
import { ConfigurationService } from '../../../services/configuration.service';
import { DatePipe } from '@angular/common';
import { ProgrammingModel } from '../../../models/programming';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './programacao.component.html',
    styleUrls: ['./programacao.component.css'],
})

export class ProgramacaoComponent extends PageBaseComponent {


    public players: any[] = [];
    public showLoading = false;
    public showLoadingProgrammingItems = false;
    public showLoadingAvailableInsertions = false;
    public showLoadingDetails = false;
    public programmings: any[] = [];
    public programmingsItems: any[] = [];
    public availableInsertionsItems: any[] = [];
    public insertionLogHistoricalDetails: any[] = [];
    public selectedProgramming: any = '';
    public editingProgramming = false;
    public savingNew = false;
    public idProgrammingEdit = '';

    public lstTiposConteudo: InsertionTypeModel[] = [];

    public playerSelected = false;
    public playerName = '';
    public property = false;
    public programmingSelected = false;

    public programmingModel: ProgrammingModel = new ProgrammingModel();

    public filter: any = {
        IdPlayer: ''
    };

    public newProgramming: NewProgrammingModel = new NewProgrammingModel();

    constructor(private playerService: PlayerService,
        private notificationService: NotificationService,
        private programmingApiService: ProgrammingApiService,
        private configurationService: ConfigurationService,
        private datePipe: DatePipe) {
        super();
    }

    public ngOnInit() {
        let instance = this;
        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;

                setTimeout(() => {
                    this.initChosen(function (source) {
                        if (source.id == 'selectedPlayers') {
                            instance.changeSelectedPlayer($('#selectedPlayers').val(), $( '#selectedPlayers option:selected' ).text());
                            instance.GetByPlayer(null);
                        }
                    });
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )

        this.initDatePickerWithCallback(function(date) {
            instance.newProgramming.InitialDate = $('#newProgrammingInitialDate').val();
            instance.newProgramming.EndDate = $('#newProgrammingEndDate').val();
        });
    }

    insertAtExecutionCycle(item: any) {
        this.programmingsItems.push(new PlayerProgrammingItemModel({
            IdPlayer: '',
            Type: item
        }));
    }

    removeAtExecutionCycle(item: any) {
        if (item.IdInsertion != '') {
            this.programmingsItems = this.programmingsItems.filter(e => e.IdInsertion != item.IdInsertion);
        } else if (item.TempId != '') {
            this.programmingsItems = this.programmingsItems.filter(e => e.TempId != item.TempId);
        }
    }

    public loadExecutionCycleConfiguration() {
        this.showLoadingProgrammingItems = true;
        this.configurationService.getAvailableInsertionTypes().subscribe(
            response => {
                this.lstTiposConteudo = response as InsertionTypeModel[];

                setTimeout(function() {
                    $('#executionCycleList').sortable();
                }, 1);

                this.showLoadingProgrammingItems = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoadingProgrammingItems = false;
            }
        )
    }

    public loadAvailableInsertions() {
        let selectedPlayers = $('#selectedPlayers').val();
        let date = new Date().getDay() + '/' + new Date().getMonth() + '/' + new Date().getFullYear();
        this.showLoadingAvailableInsertions = true;
        this.programmingApiService.GetAvailableInsertion(selectedPlayers, this.datePipe.transform(this.formatDateToParameterService(date), 'dd-MM-yyyy')).subscribe(
            response => {
                this.availableInsertionsItems = response;
                this.showLoadingAvailableInsertions = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoadingAvailableInsertions = false;
            }
        )
    }

    public GetProgrammingItems(selectedProgramming: any, date: any) {

        this.savingNew = false;
        this.idProgrammingEdit = selectedProgramming.Identificator;
        this.programmingModel = selectedProgramming;
        this.selectedProgramming = date;
        this.showLoadingProgrammingItems = true;

        this.loadAvailableInsertions();
        this.loadExecutionCycleConfiguration();

        this.programmingApiService.GetProgrammingItems(selectedProgramming.Identificator).subscribe(
            response => {
                this.programmingSelected = true;
                this.programmingsItems = [];
                response.forEach(element => {
                    this.programmingsItems.push(new PlayerProgrammingItemModel(element));
                });
                this.showLoadingProgrammingItems = false;

                setTimeout(function() {
                    $('#executionCycleList').sortable();
                }, 1);

            }, err => {
                this.showLoadingProgrammingItems = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    public GetByPlayer(identificator: any) {

        this.showLoading = true;

        let selectedPlayers = $('#selectedPlayers').val();

        this.programmingApiService.GetByPlayer(selectedPlayers).subscribe(
            response => {
                this.programmings = [];
                this.programmings = response;
                this.showLoading = false;
            }, err => {
                this.showLoading = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        let instance = this;
    }

    removeInsertion(identification) {
        let instance = this;
        let selectedPlayers = $('#selectedPlayers').val();

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover a programação selecionada?', 'warning', function () {

            instance.programmingApiService.Delete(identification).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Solicitação de remoção enviada com sucesso!');
                    instance.GetByPlayer(selectedPlayers);
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao solicitar a remoção!');
                }
            );

        }, function () {
            // Do nothing
        });
    }

    public getInsertionLogDetails(insertion: any) {

        this.showLoadingDetails = true;

        this.programmingApiService.getInsertionLogDetails(insertion).subscribe(
            response => {
                this.insertionLogHistoricalDetails = [];
                this.insertionLogHistoricalDetails = response;
                this.showLoadingDetails = false;
            }, err => {
                this.showLoadingDetails = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    changeSelectedPlayer(idPlayer, playerName) {
        this.playerSelected = true;
        this.programmingSelected = false;

        this.playerName = playerName;
    }

    insertAtProgramming(item: any) {
        this.programmingsItems.push(new PlayerProgrammingItemModel({
            IdPlayer: '',
            InsertionType: item,
            DescriptionType: item.Description,
            IdInsertionType: item.InsertionTypeId
        }));
    }

    getProgrammingList() {
        let sequence = 1;
        let instance = this;

        let dataList = $('#executionCycleList li').map(function() {
            return new PlayerProgrammingItemModel({
                Identificator: $(this).attr('data-identificator'),
                IdInsertionType: $(this).attr('data-insertion-type-id'),
                Sequence: sequence++
            });
        }).get();

        return dataList as PlayerProgrammingItemModel[];
    }

    saveProgramming() {

        //var instance = this;

        if (this.savingNew == true){
            this.newProgramming = new NewProgrammingModel();
            this.newProgramming.InitialDate = this.getCurrentDate();
            this.newProgramming.EndDate = this.getCurrentDate();
            this.selectedProgramming = this.newProgramming;

            $('#selectedPlayersNewProgramming').find('option').removeAttr('selected');
            $('#selectedPlayersNewProgramming').find('option[value="' + $('#selectedPlayers').val() + '"]').attr('selected', 'selected')
            $('.chosen-select').trigger('chosen:updated');

            $('#modalNovaProgramacao').modal('show');
        }
        else{
            //Salvando edição
            this.saveEditingProgramming();
        }

    }

    saveEditingProgramming(){
        let instance = this;

        instance.programmingModel.Identificator = this.idProgrammingEdit;
        instance.editingProgramming = true;
        instance.programmingModel.LstProgrammingItemVM = this.getProgrammingList();
        //instance.programmingModel.InitialDate = this.newProgramming.InitialDate;
        //instance.programmingModel.EndDate = this.newProgramming.EndDate;
        //instance.programmingModel.LstIdPlayers = $('#selectedPlayersNewProgramming').val();
        instance.programmingApiService.edit(this.programmingModel).subscribe(
            response => {
                this.showLoading = false;
                instance.notificationService.showSuccessNotification('', 'Programação salva com sucesso!');
                this.programmingSelected = false;
                let selectedPlayers = $('#selectedPlayers').val();
                instance.GetByPlayer(selectedPlayers);
                $('#modalNovaProgramacao').modal('hide');
            }, err => {
                this.showLoading = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    saveProgrammingModal(){
        let instance = this;

        instance.editingProgramming = true;
        instance.programmingModel.LstProgrammingItemVM = this.getProgrammingList();
        instance.programmingModel.InitialDate = this.newProgramming.InitialDate;
        instance.programmingModel.EndDate = this.newProgramming.EndDate;
        instance.programmingModel.LstIdPlayers = $('#selectedPlayersNewProgramming').val();
        instance.programmingApiService.add(this.programmingModel).subscribe(
            response => {
                this.showLoading = false;
                instance.notificationService.showSuccessNotification('', 'Programação incluída com sucesso!');
                this.programmingSelected = false;
                let selectedPlayers = $('#selectedPlayers').val();
                instance.GetByPlayer(selectedPlayers);
                $('#modalNovaProgramacao').modal('hide');
            }, err => {
                this.showLoading = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    createNewProgramming() {
        this.savingNew = true;
        this.idProgrammingEdit = '';
        this.programmingSelected = true;
        this.programmingsItems = [];

        this.selectedProgramming = null;

        this.loadAvailableInsertions();
        this.loadExecutionCycleConfiguration();
    }

    updateModelNewProgramming() {

    }
}
