export class PlayerBusinessHourModel {
    public IdPlayer: string;
    public IdBusinessHour: string;
    public WeekDay: number;
    public StartHour: number;
    public EndHour: number;
    public StartMinute: number;
    public EndMinute: number;

    public constructor( data: any = {}) {
        this.IdPlayer = data.IdPlayer || '';
        this.IdBusinessHour = data.IdBusinessHour || '';
        this.WeekDay = data.WeekDay || 0;
        this.StartHour = data.StartHour || 0;
        this.EndHour = data.EndHour || 0;
        this.StartMinute = data.StartMinute || 0;
        this.EndMinute = data.EndMinute || 0;
    }
}
