
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';
import { AdvertiserModel, AdvertisingContractModel} from '../models';

@Injectable()
export class AdvertiserService {

    constructor(public auth: AuthService, public config: Configuration, public http: Http) {
    }

    public getAllAssociatedToLoggedUser(): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/advertiser/getallassociatedtologgeduser', options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    saveRecord(advertiser: AdvertiserModel) {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};
        //ensure imports are included
         //import { Http, Response } from '@angular/http';
         //import 'rxjs/add/operator/map';

        return this.http.post(this.config.serverWithApiUrl + 'customer/advertiser/addadvertiser', advertiser, options).pipe(
        map((response: Response) => {
            return true;
        }),
        catchError((error: any) => {
            return observableThrowError(error.json() || error.json().error || 'Server error');
        }), );
     }

     public delete(advertiserId: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });

        const id = advertiserId.replace('/', '__');
        return this.http.delete(this.config.serverWithApiUrl + 'customer/advertiser/delete/' + id, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public update(advertiser: AdvertiserModel): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = advertiser;

        return this.http.put(this.config.serverWithApiUrl + 'customer/advertiser/update', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public getDetail(advertiserId: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type' : 'application/json', 'Accept': 'application/json', 'Accept-Language' : 'pt-BR' });
        const options = new RequestOptions({ headers: headers });

        const id = advertiserId.replace('/', '__');
        return this.http.get(this.config.serverWithApiUrl + 'customer/advertiser/getdetail/' + id, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }
}
