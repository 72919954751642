import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppTranslateService } from '../../../services';

@Component({
    selector: 'error-500',
    templateUrl: './error-500.component.html'
})
export class Error500Component {

    constructor(private translate: AppTranslateService) {

    }

    public ngOnInit() {
        this.translate.get(['DEFAULT.ERROR_500_TITLE']).subscribe(
            res => {
                window.document.title = window.document.title + ' - ' + res['DEFAULT.ERROR_500_TITLE'];
            }
        )
    }

    public ngOnDestroy() {

    }
}
