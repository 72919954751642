import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService, ViewerExecutionApiService } from 'app/services';
import { Observable } from 'rxjs';
// import { setTimeout } from 'timers';
import { Router } from '@angular/router';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { ViewerExecutiontModel } from 'app/models/viewer-execution';
import { DatePipe } from '@angular/common';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './visualizador-de-execucao.component.html',
    styleUrls: ['./visualizador-de-execucao.component.css'],
})

export class VisualizadorDeExecucaoComponent extends PageBaseComponent {

    public players: any[] = [];

    public showLoading = false;
    public showLoadingDetails = false;

    public executionItens: any[] = [];

    public insertions: any[] = [];
    public insertionHistoricalDetails: any[] = [];

    public titleInsertionSelected: string;
    public textInsertionSelected: string;
    public viewerExecutiontData: ViewerExecutiontModel = new ViewerExecutiontModel({
        group: '',
        playerIdentificator: '',
        date: ''
    });

    constructor(private viewerExecutionApiService: ViewerExecutionApiService,
        private playerService: PlayerService,
        private notificationService: NotificationService,
        private datePipe: DatePipe) {
        super();
    }

    public ngOnInit() {

        this.viewerExecutiontData.group = true;

        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;

                setTimeout(() => {
                    this.initChosen(function () {

                    });
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )

        this.viewerExecutiontData.date = this.getCurrentDate();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initDatePicker();
        this.initChosen(function () {

        });
    }

    removeInsertion(insertion, type) {
        let instance = this;
        let selectedPlayers = $('#selectedPlayers').val();

        this.notificationService.showConfirmationMessage('Alerta de exclusão', 'Deseja realmente remover a inserção selecionada?', 'warning', function () {

            instance.viewerExecutionApiService.Delete(insertion, type, selectedPlayers).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('', 'Solicitação de remoção enviada com sucesso!');
                    instance.getExectionList();
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao solicitar a remoção!');
                }
            );

        }, function () {
            // Do nothing
        });
    }

    openModalInsertionViewer(urlToContent, title, category) {

        $('#modalViewerImg').attr('src', '').hide();
        $('#modalViewerVideo').remove();

        if ( (/\.(gif|jpg|jpeg|tiff|png)$/i).test(urlToContent) ) {
            $('#modalViewerImg').attr('src', urlToContent).show();
        } else if ( (/\.(mp4)$/i).test(urlToContent) ) {

            let video = $('<video />', {
                id: 'modalViewerVideo',
                src: urlToContent,
                type: 'video/mp4',
                controls: true,
                width: '100%',
            });

            $('#modalViewerVideoPlaceholder').append(video);
        }

        this.titleInsertionSelected = title;
        this.textInsertionSelected = category;


        $('#modalViewer').modal('show');
        $('#modalViewer').on('hidden.bs.modal', function () {
            $('#modalViewerImg').attr('src', '').hide();
            $('#modalViewerVideo').remove();
        });
    }

    closeModalInsertionViewer() {
        $('#modalViewer').modal('hide');
    }

    public getExectionList() {
        let date = $('#viewerData').val();
        let selectedPlayers = $('#selectedPlayers').val();

        if (this.viewerExecutiontData.group) {
            this.showLoading = true;
            this.viewerExecutionApiService.GetByPlayer(selectedPlayers).subscribe(
                response => {
                    this.insertions = response;
                    this.showLoading = false;
                }, err => {
                    this.showLoading = false;
                    if (err.status === 401 || err.status === 403) return;
                    this.notificationService.showErrorMessage(err);
                }
            )
        }
        else {

            if (date == '' || date == null || selectedPlayers == '' || selectedPlayers == null) {
                this.notificationService.showErrorNotification('Por favor preencha os filtros.');
            } else {
                this.showLoading = true;
                this.viewerExecutionApiService.ReportByPlayerAndDate(selectedPlayers, this.datePipe.transform(this.formatDateToParameterService(date), 'dd-MM-yyyy')).subscribe(
                    response => {
                        this.executionItens = response;
                        this.showLoading = false;
                    }, err => {
                        this.showLoading = false;
                        if (err.status === 401 || err.status === 403) return;
                        this.notificationService.showErrorMessage(err);
                    }
                )
            }
        }
    }


    public getInsertionDetails(insertion: any) {

        this.showLoadingDetails = true;

        this.viewerExecutionApiService.GetByDetailsByInsertion(insertion).subscribe(
            response => {
                this.insertionHistoricalDetails = [];
                this.insertionHistoricalDetails = response;
                this.showLoadingDetails = false;
            }, err => {
                this.showLoadingDetails = false;
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }
}
