import { Component, OnInit, AfterViewInit } from '@angular/core';
import {  NotificationApiService, NotificationService } from '../../../services';

@Component({
    selector: 'home',
    templateUrl: './notificacoes.component.html',
    styleUrls: ['./notificacoes.component.css'],
})
export class NotificacoesComponent {

    public notifications: any[] = [];
    public showLoading = true;

    constructor(private notificationApiService: NotificationApiService, private notificationService: NotificationService) {

    }

    public ngOnInit() {
        this.loadNotifications();
    }

    public loadNotifications() {
        this.notificationApiService.getLastAssocied().subscribe(
            response => {
                this.notifications = response;
                this.showLoading = false;
            },
            error => {
                this.showLoading = false;
            }
        )
    }

    markedAsRead(identificator: string) {
        this.showLoading = true;
        this.notificationApiService.markAsReaded(identificator).subscribe(
          response => {
            this.notificationService.showSuccessNotification('Notificação removida');
            this.loadNotifications();
          },
          error => {
            this.showLoading = false;
            this.notificationService.showMessage('', error.Message, 'error');
          }
        )
      }

    public ngOnDestroy() {
    }
}
