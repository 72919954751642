import { InsertionTypeModel, PlayerProgrammingItemModel } from 'app/models';


export class ProgrammingModel {
    public Identificator: string;
    public InitialDate: string;
    public EndDate: string;
    public LstProgrammingItemVM: PlayerProgrammingItemModel[];
    public LstIdPlayers: string[];

    public constructor( data: any = {}) {
        this.LstProgrammingItemVM = data.LstProgrammingItemVM;
        this.InitialDate = data.InitialDate || '';
        this.EndDate = data.EndDate || '';
        this.Identificator = data.Identificator || '';
        this.LstIdPlayers = data.LstIdPlayers;
    }
}
