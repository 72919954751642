import { ContentBaseModel } from './content-base';

export class HtmlContentModel extends ContentBaseModel {
    public HtmlContent: string;
    public constructor( data: any = {}) {
        super();
        this.HtmlContent = data.HtmlContent || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 &&
            this.PlayersList.length > 0 && this.FileExtension.length > 0 && this.HtmlContent.length > 0;
    }
}
