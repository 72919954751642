export class MonitorAttendanceModel {
    public IdMonitorAttendance: number;
    public TicketNumber: string;
    public ArriveDate: Date;
    public CallDate: Date;
    public Preferential: boolean;
    public NameCalled: string;
    public ServiceType: string;
    public EndAttendanceDate: Date;
    public Identificator: string;
    public PointOfService: string;
    public WaitingTime: string;
    public ServiceTime: string;

    public constructor( data: any = {}) {
        this.IdMonitorAttendance = data.IdMonitorAttendance || 0;
        this.TicketNumber = data.TicketNumber || '';
        this.ArriveDate = data.ArriveDate || '';
        this.CallDate = data.CallDate || '';
        this.Preferential = data.Preferential || false;
        this.NameCalled = data.NameCalled || '';
        this.ServiceType = data.ServiceType || '';
        this.EndAttendanceDate = data.EndAttendanceDate || '';
        this.Identificator = data.Identificator || '';
        this.PointOfService = data.PointOfService || '';
        this.WaitingTime = data.WaitingTime || '';
        this.ServiceTime = data.ServiceTime || '';
    }
}
