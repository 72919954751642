import { RSSContentChannelModel, RSSNewsRestrictionModel } from 'app/models';

export class PlayerRssContentModel {
    public IdPlayer: string;
    public LstRssContentChannel: RSSContentChannelModel [];
    public LstRssNewsRestriction: RSSNewsRestrictionModel [];


    public constructor( data: any = {}) {
        this.IdPlayer = data.IdPlayer || '';
        this.LstRssContentChannel = data.LstRssContentChannel;
        this.LstRssNewsRestriction = data.LstRssNewsRestriction;
    }
}
