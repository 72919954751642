export class PlayerNotificationControlModel {
    public IdPlayer: string;
    public IdNotificationControl: string;
    public IdNotificationControlType: number;
    public Description: string;
    public SendNotificationEmail: boolean;

    public constructor( data: any = {}) {
        this.IdPlayer = data.IdPlayer || '';
        this.IdNotificationControl = data.IdNotificationControl || '';
        this.Description = data.Description || '';
        this.SendNotificationEmail = data.SendNotificationEmail || false;
    }
}
