import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services';

@Component({
    selector: 'app-quick-access',
    templateUrl: './quick-access.component.html',
    styleUrls: ['./quick-access.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class QuickAccessComponent {

    constructor(private authService: AuthService) {

    }
}
