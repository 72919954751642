import { ContentBaseModel } from './content-base';

export class MultimediaContentModel extends ContentBaseModel {
    public Duration: number;

    public constructor( data: any = {}) {
        super();
        this.Duration = data.Duration || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.Duration > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 &&
            this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
