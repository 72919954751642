import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlBase } from './control-base';
import { FormControlService } from './form-control.service';

@Component({
    selector: 'appc-dynamic-form',
    templateUrl: './dynamic-form.component.html'
})
export class DynamicFormComponent implements OnInit {

    @Input() public controls: Array<ControlBase<any>> = [];
    @Input() public btnText = 'Submit'; // Default value at least
    @Input() public formClass = 'form';
    @Input() public btnBackText = 'Back to list';
    @Input() public btnBackLink = '/home';
    // Note: don't keep name of output events as same as native events such as submit etc.
    @Output() public formsubmit: EventEmitter<any> = new EventEmitter<any>();
    public form: FormGroup;

    constructor(public _controlService: FormControlService) { }

    public ngOnInit() {
        const sortedControls = this.controls.sort((a, b) => a.order - b.order);
        this.form = this._controlService.toControlGroup(sortedControls);
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        //console.log('ngOnChanges = ', changes['controls']);
    }

    public onSubmit() {
        this.formsubmit.emit(this.form.value);
    }

    public isSelectedTab(tab: any): boolean {
        return true;
    }

    public selectTab(tab: any) {

    }

    public changeControlValue(key: any, value: any) {
        for (let i = 0; i < this.controls.length; i++) {
            if (this.controls[i].key === key) {
                this.controls[i].value = value;
                continue;
            }
        }

        const sortedControls = this.controls.sort((a, b) => a.order - b.order);
        this.form = this._controlService.toControlGroup(sortedControls);
    }
}
