import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuth = this.auth.isAuthenticated();

    if (isAuth !== true) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, expired: 1 }});
      return false;
    }

    return isAuth;
  }
}
