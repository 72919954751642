import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService, NotificationService, WarningApiService, ConfigurationService, RemoteCommandApiService } from '../../../services';
import {
    PlayerModel, PlayerLicenseInfoModel, LiveUrlModel, RSSContentChannelModel, RSSFontModel,
    RSSNewsRestrictionModel, PlayerNotificationControlModel, PlayerExecutionCycleModel,
    PlayerGeneralConfigurationModel, PlayerBusinessHourModel, InsertionTypeModel, ProgrammingType, Logo, RemoteCommandType
} from '../../../models';
import { AgmMap } from '@agm/core/directives/map';
import { LatLngBounds, MapsAPILoader } from '@agm/core';
import { marker } from 'app/components/models';
import { PlayerRssContentModel } from 'app/models/player-rss-content';
import { PageBaseComponent } from '../page-base.component';
//import { $ } from 'protractor';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './meus-paineis-details.component.html',
    styleUrls: ['./meus-paineis-details.component.css'],
})
export class MeusPaineisDetailsComponent extends PageBaseComponent {

    //public logotipoFile: any[];
    public CommandTyp: any;
    public details: PlayerModel = new PlayerModel();
    public licenseInfo: PlayerLicenseInfoModel = new PlayerLicenseInfoModel();
    public warnings: any[] = [];
    public remoteCommands: any[] = [];


    public showLoadingMap = true;
    public showLoadingWarnings = true;
    public showLoadingRemoteCommands = true;
    public showLoading = false;
    public idRssFontSelected = '';
    public idRemoteCommandType = '';
    public lstTiposConteudo: InsertionTypeModel[] = [];
    public CommandType= '';
    public useProxyckb = false;

    public playerName = '';

    public rssFonts: RSSFontModel[] = [];
    //public lstCanaisRSS: RSSContentChannelModel[] = [];

    //General configuration
    public playerGeneralConfiguration: PlayerGeneralConfigurationModel;

    public listOfRemoteCommandTypes: RemoteCommandType[] = [];

    public listOfProgramminTypes: ProgrammingType[] = [];

    //RSS
    public playerRSSContent: PlayerRssContentModel;
    public rssFontSelected: RSSFontModel = new RSSFontModel();


    //Notification control
    public lstNotificacoesEmail: PlayerNotificationControlModel[] = [];

    //Execution Cycle
    public lstExecutionCycle: PlayerExecutionCycleModel[] = [];

    //Operation time
    public lstBusinessHour: PlayerBusinessHourModel[] = [];

    // google maps zoom level
    zoom = 4;

    // Days of week
    public daysOfWeek: any[] = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    public markers: marker[] = [
    ];

    // initial center position for the map
    public lat = 51.673858;
    public lng = 7.815982;

    @ViewChild(AgmMap) public agmMap: AgmMap

    public fitBounds: LatLngBounds;

    public playerId: any;

    public citiesAvailable: any[] = [];

    public searchCityName: any = '';

    constructor(private playerService: PlayerService,
        private activatedRoute: ActivatedRoute,
        private mapsAPILoader: MapsAPILoader,
        private notificationService: NotificationService,
        private warningApiService: WarningApiService,
        private configurationService: ConfigurationService,
        private remoteCommand: RemoteCommandApiService) {
        super();
    }

    public ngOnInit() {
        const instance = this;
        const id = this.activatedRoute.snapshot.params['id'];
        if (id) {
            this.playerId = id;

            this.loadLicenseInfo(id);
            this.playerGeneralConfiguration = new PlayerGeneralConfigurationModel();
            this.playerRSSContent = new PlayerRssContentModel();
            this.loadWarnings(id);
            this.loadRemoteCommands(id);
            this.loadTypesOfRemoteCommands();
            this.playerService.getById(id).subscribe(
                result => {
                    setTimeout(() => {
                        this.initChosen(function (source) {
                            // if(source.id == 'selectedPlayers') {
                            //     instance.changeSelectedPlayer($('#selectedPlayers').val(), $( "#selectedPlayers option:selected" ).text());
                            //     instance.GetByPlayer(null);
                            // }
                        });
                    }, 0);

                    this.details = new PlayerModel(result);
                    this.playerName = this.details.Name;
                    this.loadMapPosition(this.details);
                },
                error => {
                    this.showLoadingMap = false;

                    this.notificationService.showErrorMessage(error);
                }
            );
        }
    }

    public onFileChanged(event: any) {
        const logo = new Logo();
        logo.Identificator = this.playerId;
        //logo.loadUploadFile(event.target.files[0]);

        const instance = this;
        const reader = new FileReader();

        reader.onload = function(){
            logo.FileContent = reader.result as String;

            instance.playerService.updatePlayerLogo(logo).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('Logo atualizado com sucesso!');

                    instance.loadLicenseInfo(instance.playerId);
                },
                error => {

                    instance.notificationService.showErrorMessage(error);
                }
            )

        };

        reader.readAsDataURL(event.target.files[0]);
    }

    public onFileChangedHC(event: any) {
        const logo = new Logo();
        logo.Identificator = this.playerId;
        //logo.loadUploadFile(event.target.files[0]);

        const instance = this;
        const reader = new FileReader();

        reader.onload = function(){
            logo.FileContent = reader.result as String;

            instance.playerService.updatePlayerLogoHC(logo).subscribe(
                response => {
                    instance.notificationService.showSuccessNotification('Logo (HoraCerta) atualizado com sucesso!');

                    instance.loadLicenseInfo(instance.playerId);
                },
                error => {

                    instance.notificationService.showErrorMessage(error);
                }
            )

        };

        reader.readAsDataURL(event.target.files[0]);
    }



    public loadTypesOfRemoteCommands() {
        this.listOfRemoteCommandTypes = [];
        const programmingTypeAutomatic = new RemoteCommandType();
        programmingTypeAutomatic.Id = 1;
        programmingTypeAutomatic.Description = 'Reiniciar Player';
        this.listOfRemoteCommandTypes.push(programmingTypeAutomatic);

        const programmingTypeSemiAutomatic = new RemoteCommandType();
        programmingTypeSemiAutomatic.Id = 2;
        programmingTypeSemiAutomatic.Description = 'Finalizar Player';
        this.listOfRemoteCommandTypes.push(programmingTypeSemiAutomatic);

        const programmingTypeManual = new RemoteCommandType();
        programmingTypeManual.Id = 3;
        programmingTypeManual.Description = 'Desligar dispositivo';
        this.listOfRemoteCommandTypes.push(programmingTypeManual);
    }

    public loadProgrammingType() {
        this.listOfProgramminTypes = [];


        const programmingTypeDetailed = new ProgrammingType();
        programmingTypeDetailed.Id = 4;
        programmingTypeDetailed.Description = 'Detalhado';
        this.listOfProgramminTypes.push(programmingTypeDetailed);

        const programmingTypeManual = new ProgrammingType();
        programmingTypeManual.Id = 3;
        programmingTypeManual.Description = 'Manual';
        this.listOfProgramminTypes.push(programmingTypeManual);

        const programmingTypeSemiAutomatic = new ProgrammingType();
        programmingTypeSemiAutomatic.Id = 2;
        programmingTypeSemiAutomatic.Description = 'SemiAutomática';
        this.listOfProgramminTypes.push(programmingTypeSemiAutomatic);

        const programmingTypeAutomatic = new ProgrammingType();
        programmingTypeAutomatic.Id = 1;
        programmingTypeAutomatic.Description = 'Automática';
        this.listOfProgramminTypes.push(programmingTypeAutomatic);
    }

    public loadRssFonts() {
        this.configurationService.getAllRssContent().subscribe(
            result => {
                this.rssFonts = result as RSSFontModel[];
                if (this.rssFonts.length > 0) {
                    this.rssFontSelected = this.rssFonts[0];
                }
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
    }



    onChangeSelectedRssFont(selectedValue) {
        this.rssFontSelected = selectedValue;
        this.idRssFontSelected = this.rssFontSelected.IdRssFont;
        //selecionados
        //playerRSSContent.LstRssContentChannel


        //playerRSSContent.LstRssContentChannel.includes()

        //disponiveis
        for (let i = 0; i < this.rssFontSelected.LstRssContentChannel.length; i++) {
            if (this.playerRSSContent.LstRssContentChannel.find(e => e.IdRssContentChannel == this.rssFontSelected.LstRssContentChannel[i].IdRssContentChannel)) {
                this.rssFontSelected.LstRssContentChannel.splice(i, 1);
            }
        }
        //this.rssFontSelected.LstRssContentChannel = this.rssFontSelected.LstRssContentChannel.filter(e => !this.playerRSSContent.LstRssContentChannel.includes(e));
    }

    addRssChanel(idRssChannel) {
        const newItem = this.rssFontSelected.LstRssContentChannel.find(e => e.IdRssContentChannel == idRssChannel);
        const itemIndex = this.rssFontSelected.LstRssContentChannel.findIndex(e => e.IdRssContentChannel == idRssChannel);
        if (newItem != null) {
            this.playerRSSContent.LstRssContentChannel.push(newItem);
            this.rssFontSelected.LstRssContentChannel.splice(itemIndex, 1);
        }

    }

    removeRssChanel(idRssChannel) {
        const itemToRemove = this.playerRSSContent.LstRssContentChannel.find(e => e.IdRssContentChannel == idRssChannel);
        const itemIndex = this.playerRSSContent.LstRssContentChannel.findIndex(e => e.IdRssContentChannel == idRssChannel);
        if (itemToRemove != null) {
            this.playerRSSContent.LstRssContentChannel.splice(itemIndex, 1);
            if (itemToRemove.RssFont.IdRssFont == this.idRssFontSelected)
                this.rssFontSelected.LstRssContentChannel.push(itemToRemove);
        }

    }

    addRestrictedWord() {
        const newWord = $('#txtPalavra').val();
        const newObjWord = new RSSNewsRestrictionModel();
        newObjWord.RestrictedWord = newWord;
        if (this.playerRSSContent.LstRssNewsRestriction == null)
            this.playerRSSContent.LstRssNewsRestriction = new Array<RSSNewsRestrictionModel>();

        this.playerRSSContent.LstRssNewsRestriction.push(newObjWord);
    }

    removeRestrictedWord(id) {

        let index;
        if (id != null) {
            if (id.length > 25) {
                index = this.playerRSSContent.LstRssNewsRestriction.findIndex(e => e.TempId == id);
            }
            else {
                index = this.playerRSSContent.LstRssNewsRestriction.findIndex(e => e.IdRssNewsRestriction == id);
            }
        }


        if (index != null)
            this.playerRSSContent.LstRssNewsRestriction.splice(index, 1);
    }

    public loadMapPosition(playerDetails: any) {

        this.markers.push({
            Identificator: playerDetails.Identificator,
            label: playerDetails.Name,
            lat: playerDetails.Latitude,
            lng: playerDetails.Longitude,
            draggable: false
        });

        this.mapsAPILoader.load().then(() => {
            this.fitBounds = new window['google'].maps.LatLngBounds();
            this.fitBounds.extend(new window['google'].maps.LatLng(playerDetails.Latitude, playerDetails.Longitude));
            this.agmMap.fitBounds = this.fitBounds;
            this.agmMap.triggerResize(true);
        });

        this.showLoadingMap = false;
    }

    public loadWarnings(id: any) {
        this.warningApiService.getByPlayer(id).subscribe(
            response => {
                this.warnings = response;
                this.showLoadingWarnings = false;
            },
            error => {
                this.showLoadingWarnings = false;
                this.notificationService.showErrorMessage(error);
            }
        )
    }

    public loadRemoteCommands(id: any) {
        this.playerService.getCommandByPlayer(id).subscribe(
            response => {
                this.remoteCommands = response;
                this.showLoadingRemoteCommands = false;
            },
            error => {
                this.showLoadingRemoteCommands = false;
                this.notificationService.showErrorMessage(error);
            }
        )
    }

    public loadLicenseInfo(identificator: any) {
        this.configurationService.getLisenseDetails(identificator).subscribe(
            response => {
                this.licenseInfo = new PlayerLicenseInfoModel(response);
                console.log(this.licenseInfo);

            },
            error => {
                this.notificationService.showErrorMessage(error);
            }
        )
    }

    public loadGeneralConfiguration(identificator: any) {
        this.showLoading = true;

        this.configurationService.getGeneralConfiguration(identificator).subscribe(
            response => {
                console.log(response);
                this.playerGeneralConfiguration = new PlayerGeneralConfigurationModel(response);
                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
        this.loadProgrammingType();
    }

    public loadRssContentConfiguration(identificator: any) {
        this.showLoading = true;
        this.loadRssFonts();

        this.configurationService.getRssContentConfiguration(identificator).subscribe(
            response => {
                this.playerRSSContent = new PlayerRssContentModel(response);
                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        );
    }

    public loadNotificationControlConfiguration(identificator: any) {

        this.showLoading = true;

        this.configurationService.getNotificationControlConfiguration(identificator).subscribe(
            response => {
                this.lstNotificacoesEmail = response as PlayerNotificationControlModel[];
                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
    }

    public loadExecutionCycleConfiguration(identificator: any) {
        this.showLoading = true;
        this.configurationService.getAvailableInsertionTypes().subscribe(
            response => {
                this.lstTiposConteudo = response as InsertionTypeModel[];
                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
        this.configurationService.getExecutionCycleConfiguration(identificator).subscribe(
            response => {
                this.lstExecutionCycle = response as PlayerExecutionCycleModel[];
                setTimeout(function () {
                    $('#executionCycleList').sortable();
                }, 1);

                this.showLoading = false;
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
    }

    public loadBusinessHourConfiguration(identificator: any) {
        const instance = this;
        this.showLoading = true;
        this.configurationService.getBusinessHourConfiguration(identificator).subscribe(
            response => {
                this.lstBusinessHour = response as PlayerBusinessHourModel[];
                this.showLoading = false;

                setTimeout(function () {
                    instance.initRangeSliderHours();
                }, 1);
            },
            error => {
                this.notificationService.showErrorMessage(error);
                this.showLoading = false;
            }
        )
    }

    markmarkedWarningAsRead(identificator: string) {

        this.showLoadingWarnings = true;
        this.warningApiService.markAsReaded(identificator).subscribe(
            response => {
                const id = this.activatedRoute.snapshot.params['id'];
                if (id) {
                    this.loadWarnings(id);
                }
                this.notificationService.showSuccessNotification('Notificação removida');
            },
            error => {
                this.notificationService.showMessage('', error.Message, 'error');
                this.showLoadingWarnings = false;
            }
        )
    }

    deleteRemoteCommand(identificator: string) {

        this.showLoadingRemoteCommands = true;
        this.remoteCommand.delete(identificator).subscribe(
            response => {
                const id = this.activatedRoute.snapshot.params['id'];
                if (id) {
                    this.loadRemoteCommands(id);
                }
                this.notificationService.showSuccessNotification('Comando remoto removido');
            },
            error => {
                this.notificationService.showMessage('', error.Message, 'error');
                this.showLoadingRemoteCommands = false;
            }
        )
    }

    getCodeCPTECByCityName(cityName: string) {

        if (cityName == undefined || cityName.length == 0) {
            this.citiesAvailable = [];
            return;
        }

        this.configurationService.getCPTECCodeByCity(cityName).subscribe(
            response => {
                this.citiesAvailable = response;
            },
            error => {
                this.notificationService.showMessage('', error.Message, 'error');
                //this.showLoadingWarnings = false;
            }
        )
    }

    selecionarTemperatura(obj) {
        this.playerGeneralConfiguration.CityCode = obj.Code;
        this.playerGeneralConfiguration.CityName = obj.CityName;
        this.searchCityName = '';
        this.citiesAvailable = [];
    }

    limparBuscaTemperatura() {
        this.searchCityName = '';
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        this.initRangeSliderHours();
    }

    changeCheckBoxProxy() {
        this.useProxyckb = !this.useProxyckb;
    }

    updatePlayerName() {

        const player = new PlayerModel()

        player.Name = this.playerName;
        player.Identificator = this.playerId;

        this.playerService.updatePlayerName(player).subscribe(
            response => {
                this.notificationService.showSuccessNotification('Informações salvas com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
    }

    updateGeneralConfiguration() {
        this.configurationService.updateGeneralConfiguration(this.playerGeneralConfiguration).subscribe(
            response => {
                this.notificationService.showSuccessNotification('Configurações salvas com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
    }

    updateExecutionCycleList() {
        let sequence = 1;
        const instance = this;

        const dataList = $('#executionCycleList li').map(function () {
            return new PlayerExecutionCycleModel({
                IdPlayer: instance.playerId,
                ExecutionCycleId: $(this).attr('data-id'),
                //InsertionType: $(this).attr('data-insertion-type') as InsertionTypeModel,
                Sequence: sequence++,
                InsertionTypeId: $(this).attr('data-insertion-type-id'),
            });
        }).get();


        this.configurationService.updateExecutionCycleList(dataList).subscribe(
            response => {
                this.notificationService.showSuccessNotification('Ciclo de execução salvo com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
        //console.log(dataList);
    }

    public sendNewRemoteCommand(form: any, model: any, isValid: boolean) {

        if (!isValid) {
            return;
        }

        this.showLoadingRemoteCommands = true;
        model.PlayerIdentificator = this.activatedRoute.snapshot.params['id'];

        this.remoteCommand.add(model).subscribe(
            response => {
                this.clearAndDismissModal();
                this.notificationService.showSuccessNotification('Comando remoto enviado com sucesso!');
                this.loadRemoteCommands(this.activatedRoute.snapshot.params['id']);
            },
            error => {
                this.clearAndDismissModal();
                this.showLoadingRemoteCommands = false;
                this.notificationService.showMessage('', error.ModelState[0], 'error');
            }
        )
    }

    public clearAndDismissModal() {

        $('#modalNewRemoteCommand').modal('hide');
    }

    getDayOfWeek(idx: any) {
        return this.daysOfWeek[idx];
    }

    insertAtExecutionCycle(item: any) {
        this.lstExecutionCycle.push(new PlayerExecutionCycleModel({
            IdPlayer: '',
            InsertionType: item
        }));
    }

    removeAtExecutionCycle(item: any) {
        if (item.ExecutionCycleId != '') {
            this.lstExecutionCycle = this.lstExecutionCycle.filter(e => e.ExecutionCycleId != item.ExecutionCycleId);
        } else if (item.TempId != '') {
            this.lstExecutionCycle = this.lstExecutionCycle.filter(e => e.TempId != item.TempId);
        }
    }

    updateRssContentConfiguration() {
        this.configurationService.updateRssContentConfiguration(this.playerRSSContent).subscribe(
            response => {
                this.notificationService.showSuccessNotification('Configurações salvas com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
    }

    updateNotificationControlConfiguration() {
        this.configurationService.updateNotificationControlConfiguration(this.lstNotificacoesEmail).subscribe(
            response => {
                this.notificationService.showSuccessNotification('Configurações salvas com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )
    }

    updateBusinessHourConfiguration() {
        const items: PlayerBusinessHourModel[] = [];

        $('#operationTime .range-slider-date').each(function () {
            const leftValue = $(this).find('.ui-rangeSlider-leftLabel > .ui-rangeSlider-label-value').html();
            const rightValue = $(this).find('.ui-rangeSlider-rightLabel > .ui-rangeSlider-label-value').html();

            const newItem = new PlayerBusinessHourModel();
            newItem.IdBusinessHour = $(this).find('#_idBusinessHour').html();
            newItem.IdPlayer = $(this).find('#_idPlayer').html();
            newItem.WeekDay = $(this).find('#_weekDay').html();
            newItem.StartHour = +leftValue.substring(0, 2);
            newItem.StartMinute = +leftValue.substring(3);
            newItem.EndHour = +rightValue.substring(0, 2);
            newItem.EndMinute = +rightValue.substring(3);


            items.push(newItem);
        });

        //console.log(items);

        this.configurationService.updateBusinessHourConfiguration(items).subscribe(
            response => {

                this.notificationService.showSuccessNotification('Configurações salvas com sucesso!');
            },
            error => {

                this.notificationService.showErrorMessage(error);
            }
        )

    }
}
