import {Pipe} from '@angular/core';

@Pipe({
    name: 'greet'
})
export class GreetPipe {
    transform(val, args) {
        if (val < 12) {
            return 'Bom dia';
        } else if (val => 12 && val <= 17) {
            return 'Boa tarde';
        } else if (val > 17 && val <= 24) {
            return 'Boa noite';
        }
    }
}
