import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, UserService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { UserPasswordRecoveryModel } from '../../../models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'recuperar-senha',
    styleUrls: ['./recuperar-senha.component.css'],
    templateUrl: './recuperar-senha.component.html'
})
export class RecuperarSenhaComponent extends PageBaseComponent {

    public userPassword: UserPasswordRecoveryModel = new UserPasswordRecoveryModel();

    public showLoading = false;

    private loginErrorMessage: string;

    private identifier: string;

    constructor(private activatedRoute: ActivatedRoute,
        private notificationService: NotificationService,
        private router: Router,
        private userService: UserService) {
        super();
    }

    public ngOnInit() {
        this.identifier = this.activatedRoute.snapshot.params['id'];

    }

    public ngOnDestroy() {
    }

    public changePassword(form: any, model: UserPasswordRecoveryModel, isValid: boolean) {

        if (!isValid) {
            return;
        }
        model.Identifier = this.identifier;
        this.userService.recoverypassword(model).subscribe(
            response => {

                let instance = this;

                this.notificationService.showMessageWithRedirect('', 'Senha alterada com sucesso!', 'success', function () {

                    instance.retornarTelaLogin();
                }, function () {
                    // Do nothing
                });
            },
            err => {
                console.log('err', err);
                this.notificationService.showMessage('', 'Não é possível alterar a senha neste momento. Tente novamente mais tarde.', 'error');
            }
        )
    }

    public retornarTelaLogin() {
        this.router.navigate(['/']);
    }
}
