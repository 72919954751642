import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService, PlayerService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { Input } from '@angular/core';
import { WebPageContentModel } from 'app/models';

declare var jquery: any;
declare var $: any;

@Component({
  selector: "envio-conteudo-web-page",
  templateUrl: './envio-conteudo-web-page.component.html',
  styleUrls: ['./envio-conteudo-web-page.component.css']
})
export class EnvioConteudoWebPageComponent extends PageBaseComponent {
  public players: any[] = [];
  public hasSelectedFiles = false;

  @Input('ngConteudo')
  public conteudo: WebPageContentModel = new WebPageContentModel();

  constructor(
    private notificationService: NotificationService,
    private playerService: PlayerService
  ) {
    super();

    this.playerService.getAllAssociatedSimpleList().subscribe(
      response => {
        this.players = response;
        this.initChosen();
      },
      err => {
        if (err.status === 401 || err.status === 403) return;
        this.notificationService.showErrorMessage(err);
      }
    );
  }

  public ngOnInit() {}

  public ngOnDestroy() {
    // removing the header
    //this.breadServ.clear();
  }

  ngAfterViewInit() {
    this.initChosen();
    this.initDatePicker();

    this.conteudo.InitialDate = this.getCurrentDate();
    this.conteudo.EndDate = this.getCurrentDate();
  }

  openFileUpload(event: any = null) {
    console.log('openFileUpload');
    $(event.target)
      .parents('.row-upload')
      .find('.file-upload')
      .click();
  }

  uploadFileChange(event: any = null) {
    let instance = this;
    let reader = new FileReader();
    reader.onload = function() {
      instance.conteudo.FileContent = reader.result as String;
      console.log(reader);
    };

    this.conteudo.FileExtension =
      '.' + this.getFileExtension(event.target.files[0].name);
    reader.readAsDataURL(event.target.files[0]);
  }

  updateModel() {
    let selectedPlayers = $('#selectedPlayers_' + this.conteudo.TempId).val();
    this.conteudo.PlayersList = selectedPlayers;

    let initialDate = $('#initialDate_' + this.conteudo.TempId).val();
    let endDate = $('#endDate_' + this.conteudo.TempId).val();

    this.conteudo.InitialDate = initialDate;
    this.conteudo.EndDate = endDate;
  }

  initChosen() {
    let instance = this;

    super.initChosen(function() {
      instance.updateModel();
    });
  }

  initDatePicker() {
    let instance = this;
    setTimeout(() => {
      $('.bootstrap-datepicker').datepicker({
        dateFormat: 'dd-mm-yy',
        onSelect: function(date) {
          instance.updateModel();
        }
      });
    }, 0);
  }
}
