import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CollectionFreeContentService } from '../../../services';
import { AppTranslateService } from '../../../services';
import { CentralContentService } from '../../../services';
import { NewsContentModel } from '../../../models/news-content';



@Component({
    selector: 'home',
    templateUrl: './central-de-conteudo.component.html',
    styleUrls: ['./central-de-conteudo.component.css'],
})
export class CentralDeConteudoComponent {

    public freeContents: any[] = [];
    public myContents: any[] = [];
    public responseStatus: Object = [];

    constructor(private centralContentService: CentralContentService, private collectionFreeContentService: CollectionFreeContentService) {
    }

    public ngOnInit() {
        //this.loadFreeContent();
        this.loadMyContent();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }


    ngAfterViewInit() {

    }

    public loadFreeContent() {

        this.collectionFreeContentService.getFreeContent().subscribe(
            response => {
                this.freeContents = response;
            },
            error => {
            }
        )
    }

    public loadMyContent() {

        this.centralContentService.getMyContent().subscribe(
            response => {
                this.myContents = response;
            },
            error => {
            }
        )

    }
}
