export class NewCustomerModel {
    public Name: string;
    public Email: string;
    public Password: string;
    public PhoneDDD: string;
    public Phone: string;
    public LicenseNumber: string;

    public constructor( data: any = {}) {
        this.Name = data.Name || '';
        this.Email = data.Email || '';
        this.Password = data.Password || '';
        this.PhoneDDD = data.PhoneDDD || '';
        this.Phone = data.Phone || '';
        this.LicenseNumber = data.LicenseNumber || '';
    }
}
