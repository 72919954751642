import { PlayerModel } from 'app/models';

export class Logo {
    public Identificator: string;
    public FileContent: String;

    public constructor( data: any = {}) {
        this.Identificator = data.IdContent || '';
        this.FileContent = data.FileContent || '';

    }

    public loadUploadFile(file: any) {
        const instance = this;
        const reader = new FileReader();

        reader.onload = function(){
            instance.FileContent = reader.result as String;

        };

        reader.readAsDataURL(file);
    }
}
