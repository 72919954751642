import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateGuard } from './services/guard.service';

// Components
import { PublicComponent, SecureComponent } from './layouts';
import { HomeComponent, LoginComponent, Error500Component, FaturamentoComponent, MeusPaineisComponent, RemocaoEmLoteComponent,
    MeusPaineisDetailsComponent, StatusServicosComponent, OcorrenciasSuporteComponent, OcorrenciasSuporteDetailsComponent, UsuariosComponent,
    LogOperacionalComponent, CentralDeConteudoComponent, VisualizadorDeExecucaoComponent,
    ProgramacaoComponent, AnunciantesContratosComponent, AtendimentosComponent, EnvioDeConteudoComponent, NotificacoesComponent,
    AlertasComponent, CampanhaComponent, RecuperarSenhaComponent } from './components';
import { GridExemploComponent } from 'app/components/pages/grid-exemplo/grid-exemplo.component';

export const PUBLIC_ROUTES: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'recuperar-senha/:id', component: RecuperarSenhaComponent },
    { path: 'error-500', component: Error500Component }
];

export const SECURE_ROUTES: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [CanActivateGuard] },
    { path: 'faturamento', component: FaturamentoComponent, canActivate: [CanActivateGuard] },
    { path: 'meus-paineis', component: MeusPaineisComponent, canActivate: [CanActivateGuard] },
    { path: 'meus-paineis/:id', component: MeusPaineisDetailsComponent, canActivate: [CanActivateGuard] },
    { path: 'status-servicos', component: StatusServicosComponent, canActivate: [CanActivateGuard] },
    { path: 'ocorrencias-suporte', component: OcorrenciasSuporteComponent, canActivate: [CanActivateGuard] },
    { path: 'ocorrencias-suporte/:id', component: OcorrenciasSuporteDetailsComponent, canActivate: [CanActivateGuard] },
    { path: 'usuarios', component: UsuariosComponent, canActivate: [CanActivateGuard] },
    { path: 'log-operacional', component: LogOperacionalComponent, canActivate: [CanActivateGuard] },
    { path: 'programacao', component: ProgramacaoComponent, canActivate: [CanActivateGuard] },
    { path: 'campanha', component: CampanhaComponent, canActivate: [CanActivateGuard] },
    { path: 'atendimentos', component: AtendimentosComponent, canActivate: [CanActivateGuard] },
    { path: 'anunciantes-contratos', component: AnunciantesContratosComponent, canActivate: [CanActivateGuard] },
    { path: 'visualizador-de-execucao', component: VisualizadorDeExecucaoComponent, canActivate: [CanActivateGuard] },
    { path: 'central-de-conteudo', component: CentralDeConteudoComponent, canActivate: [CanActivateGuard] },
    { path: 'remocao-em-lote', component: RemocaoEmLoteComponent, canActivate: [CanActivateGuard] },
    { path: 'envio-de-conteudo', component: EnvioDeConteudoComponent, canActivate: [CanActivateGuard] },
    { path: 'notificacoes', component: NotificacoesComponent, canActivate: [CanActivateGuard] },
    { path: 'alertas', component: AlertasComponent, canActivate: [CanActivateGuard] },
    { path: 'grid-exemplo', component: GridExemploComponent, canActivate: [CanActivateGuard] },
];

const APP_ROUTES: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full', },
    { path: '', component: PublicComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
    { path: '', component: SecureComponent, canActivate: [CanActivateGuard], data: { title: 'Secure Views' }, children: SECURE_ROUTES }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES);
