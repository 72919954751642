import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { LoginModel } from '../../../models';
import { ViewChild } from '@angular/core';
import { AppTranslateService, NotificationService, UserService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { Params } from '@angular/router/src/shared';

declare var $: any;

@Component({
    //moduleId: module.id,
    selector: 'app-login',
    styleUrls: ['./login.component.css'],
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

    public showLoading = false;

    public loginData: LoginModel = new LoginModel({
        userName: '',
        password: ''
    });

    public loginRecoveryData: LoginModel = new LoginModel({
        userName: '',
        password: ''
    });

    private loginErrorMessage: string;

    constructor(public auth: AuthService, private router: Router,
        private translate: AppTranslateService,
        private activatedRoute: ActivatedRoute,
        private notificationService: NotificationService,
        private userService: UserService) {
        this.translate.get(['DEFAULT.LOGIN_ERROR']).subscribe((res: string[]) => {
            this.loginErrorMessage = res['DEFAULT.LOGIN_ERROR'];
        });

        // this.activatedRoute.queryParams.subscribe((params: Params) => {
        //     let expired = params['expired'];
        //     if (expired) {
        //         this.notificationService.showErrorMessage({
        //             Message: 'Sua sessão expirou, você precisa entrar novamente!'
        //         });
        //     }
        // });
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
    }

    public recoveyPassword() {
        this.userService.sendmailrecoverypassword(this.loginRecoveryData.userName).subscribe(
            response => {
                this.notificationService.showSuccessNotification('', 'Se este email estiver cadastrado você receberá uma notificação para relembrar a senha');
                $('#modalRecoveryPassword').modal('hide');
            },
            err => {
                if (err.Message) {
                    this.notificationService.showErrorNotification('', 'Erro recuperar a senha do usuário: ' + err.Message);
                } else {
                    this.notificationService.showErrorNotification('', 'Erro recuperar a senha do usuário.');
                }
            }
        );
    }

    public login() {
        this.showLoading = true;

        this.auth.login(this.loginData)
            .subscribe(
                result => {
                    if (result === true) {

                        this.auth.getMe().subscribe(
                            response => {
                                this.router.navigate(['/']);
                            },
                            error => {
                                this.notificationService.showMessage('', this.loginErrorMessage, 'error');
                            }
                        );

                    } else {
                        this.notificationService.showMessage('', this.loginErrorMessage, 'error');
                        this.showLoading = false;
                    }
                },
                err => {
                    this.notificationService.showMessage('', err, 'error');
                    this.showLoading = false;
                });
    }

}
