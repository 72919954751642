import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService, AuthService, MonitorAttendanceService } from 'app/services';
import { Observable } from 'rxjs';
// import { setTimeout } from 'timers';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { UserModel, MonitorAttendanceModel, MonitorAttendanceFilterModel, PointOfServiceModel } from '../../../models';
import { DatePipe } from '@angular/common';
import { ServiceTypeModel } from '../../../models/service-type';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './atendimentos.component.html',
    styleUrls: ['./atendimentos.component.css'],
})
export class AtendimentosComponent extends PageBaseComponent {

    public lstMonitorAttendanceModel: MonitorAttendanceModel[];
    public filter: MonitorAttendanceFilterModel = new MonitorAttendanceFilterModel();
    public players: any[] = [];
    public showLoading = false;
    public lstPointOfService: PointOfServiceModel[];
    public lstServieType: ServiceTypeModel[];
    public lstMonitorAttendanceGrouped: any[] = [];

    public groupingBy = '1'

    // Pie
    public pieChartLabels: string[] = [];
    public pieChartData: number[] = [];
    public pieChartType = 'pie';

    constructor(private monitorAttendanceService: MonitorAttendanceService,
        private playerService: PlayerService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private datePipe: DatePipe) {
        super();

        this.lstMonitorAttendanceModel = [];
        this.lstMonitorAttendanceGrouped = [];
        this.lstPointOfService = [];
        this.lstServieType = [];

    }

    public ngOnInit() {

        const instance = this;
        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;

                setTimeout(() => {
                    this.initChosen(function () {
                        instance.filter.IdPlayer = $('#selectedPlayers').val();
                        instance.changeSelectedPlayer(instance.filter.IdPlayer);
                    });
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )

        this.filter.InitialDate = this.getCurrentDate();
        this.filter.EndDate = this.getCurrentDate();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        const instance = this;

        this.initDatePickerWithCallback(function (date) {
            const initialDate = $('#initialDate').val();
            const endDate = $('#endDate').val();

            instance.filter.InitialDate = initialDate;
            instance.filter.EndDate = endDate;
        });
        this.initChosen(function () {
            instance.filter.IdPlayer = $('#selectedPlayers').val();
        });

        $('#modalMensagem').modal('show');
    }

    public search() {

        if (this.groupingBy == '4') {
            this.loadWithTotalizationByDay();
        }
        else{
            this.loadCompleteReportData();
        }

    }

    onSelectionChange(entry) {
        this.groupingBy = entry;
    }

    public loadCompleteReportData() {

        const instance = this;
        this.showLoading = true;
        const newFilter = new MonitorAttendanceFilterModel({
            IdPlayer: this.filter.IdPlayer,
            InitialDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.InitialDate), 'dd-MM-yyyy'),
            EndDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.EndDate), 'dd-MM-yyyy'),
            IdPointOfService: this.filter.IdPointOfService,
            ServiceType: this.filter.ServiceType
        });

        this.monitorAttendanceService.getCompleteReport(newFilter).subscribe(
            response => {
                this.lstMonitorAttendanceModel = response as MonitorAttendanceModel[];

                this.loadPie();

                this.showLoading = false;
            },
            err => {
                this.showLoading = false;
            }
        )
    }

    public loadWithTotalizationByDay() {

        const instance = this;
        this.showLoading = true;
        const newFilter = new MonitorAttendanceFilterModel({
            IdPlayer: this.filter.IdPlayer,
            InitialDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.InitialDate), 'dd-MM-yyyy'),
            EndDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.EndDate), 'dd-MM-yyyy'),
            IdPointOfService: this.filter.IdPointOfService,
            ServiceType: this.filter.ServiceType
        });

        this.monitorAttendanceService.getWithTotalizationByDay(newFilter).subscribe(
            response => {
                this.lstMonitorAttendanceGrouped = response;
                this.showLoading = false;
            },
            err => {
                this.showLoading = false;
            }
        )
    }

    public loadPie() {

        this.pieChartLabels = [];
        this.pieChartData = [];
        let grouped;

        if (this.groupingBy == '2') {
            grouped = super.groupBy(this.lstMonitorAttendanceModel, 'ServiceType');
        }
        else if (this.groupingBy == '3') {
            grouped = super.groupBy(this.lstMonitorAttendanceModel, 'PointOfService');
        }



        for (const key in grouped) {
            const value = grouped[key];
            this.pieChartLabels.push(key);
            this.pieChartData.push(value.length);
        }
    }

    public print() {
        window.print();
    }

    public downloadExcel() {

        const instance = this;
        this.showLoading = true;

        const newFilter = new MonitorAttendanceFilterModel({
            IdPlayer: this.filter.IdPlayer,
            InitialDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.InitialDate), 'dd-MM-yyyy'),
            EndDate: this.datePipe.transform(instance.formatDateToParameterService(this.filter.EndDate), 'dd-MM-yyyy'),
            IdPointOfService: this.filter.IdPointOfService,
            ServiceType: this.filter.ServiceType
        });

        this.monitorAttendanceService.report(newFilter).subscribe(
            response => {
console.log(response);
                const blob = new Blob(['\ufeff' + response.Content], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', 'report.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);

        this.showLoading = false;
            },
            err => {
                this.showLoading = false;
            }
        )
    }

    changeSelectedPlayer(idPlayer) {

        this.monitorAttendanceService.getPointOfServicesByPlayer(idPlayer).subscribe(
            response => {
                this.lstPointOfService = response as PointOfServiceModel[];
                this.showLoading = false;
            },
            err => {
                this.showLoading = false;
            }
        )

        this.monitorAttendanceService.getServiceTypesByPlayer(this.filter.IdPlayer).subscribe(
            response => {
                this.lstServieType = response as ServiceTypeModel[];
                this.showLoading = false;
            },
            err => {
                this.showLoading = false;
            }
        )

    }


}
