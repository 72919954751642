import { ContentBaseModel } from 'app/models/content-base';

export class AdvertisingContractModel extends ContentBaseModel {
    public Comments: string;
    public Duration: number;
    public AdvertiserId: string;

    public constructor( data: any = {}) {
        super();
        this.Title = data.Title || '';
        this.InitialDate = data.InitialDate || '';
        this.EndDate = data.EndDate || '';
        this.Comments = data.Comments || '';
        this.Duration = data.Duration || 0;
        this.AdvertiserId = data.AdvertiserId || '';
    }

    public isValid(): boolean {
        return this.Title.length > 0 && this.InitialDate.length > 0 &&
            this.EndDate.length > 0 && this.FileContent.length > 0 &&
            this.PlayersList.length > 0 && this.FileExtension.length > 0;
    }
}
