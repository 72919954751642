export class PaymentCardModel {
    public idInvoice: string;
    public cardNumber: string;
    public validateMouth: string;
    public validateYear: string;
    public cvvCode: string;
    public cardHolder: any;

    public constructor( data: any = {}) {
        this.idInvoice = data.idInvoice || '';
        this.cardNumber = data.cardNumber || '';
        this.validateMouth = data.validateMouth || '';
        this.validateYear = data.validateYear || '';
        this.cvvCode = data.cvvCode || '';
        this.cardHolder = data.cvvCode || '';
    }
}
