import { Component, OnInit, AfterViewInit } from '@angular/core';
import {  ServiceStatusService, NotificationService } from '../../../services';
import { AppTranslateService } from '../../../services';


@Component({
    selector: 'home',
    templateUrl: './status-servicos.component.html',
    styleUrls: ['./status-servicos.component.css'],
})
export class StatusServicosComponent {

    public integrationStatus: any[] = [];
    public showLoading = true;

    constructor(private serviceStatusService: ServiceStatusService, private notificationService: NotificationService) {

    }

    public ngOnInit() {
        this.loadStatusIntegration();
    }

    public loadStatusIntegration() {
        this.serviceStatusService.getAll().subscribe(
            response => {
                this.integrationStatus = response;
                this.showLoading = false;
            },
            error => {
                this.showLoading = false;
                this.notificationService.showMessage('', error.Message, 'error');
            }
        )
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {
        //this.showLoading = false;
    }
}
