import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BillingService, NotificationService, MockDataService } from '../../../services';
import { PaymentCardModel } from 'app/models/payment-card';
import { PageBaseComponent } from 'app/components/pages/page-base.component';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './grid-exemplo.component.html',
    styleUrls: ['./grid-exemplo.component.css'],
})
export class GridExemploComponent extends PageBaseComponent {

    public rows: any[] = [];
    public showLoading = true;
    public searchText = '';
    public currentPage = 1;
    public maxRows = 50;
    public totalRows = 1000;
    public firstLoad = true;

    constructor(private notificationService: NotificationService, private mockDataService: MockDataService) {
        super();
    }

    public ngOnInit() {
        this.loadData();
    }

    public loadData() {
        this.mockDataService.getAll(this.searchText, this.currentPage, this.maxRows).subscribe(
            response => {
                this.rows = response;
                this.showLoading = false;

                if (this.firstLoad) {
                    this.firstLoad = false;

                    setTimeout(() => {
                        $('#tblRows').DataTable({
                            paging: false
                        });
                    }, 0);
                }
            }
        )
    }

    public pageChanged(event: any): void {
        this.currentPage = event.page;
        this.loadData();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {

    }
}
