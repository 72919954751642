
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AuthService } from './auth.service';
import { Configuration } from '../app.constants';

@Injectable()
export class ViewerExecutionApiService {

    constructor(public auth: AuthService, public config: Configuration, public http: Http) {
    }

    public ReportByPlayerAndDate(identificator: string, date: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/viewerexecution/reportbyplayeranddate/' + identificator + '/' + date, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public GetByPlayer(identificator: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/viewerexecution/getbyplayer/' + identificator, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public GetAllInsertions(identificator: string, insertionsTypes: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/viewerexecution/allinsertions/' + identificator, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public BatchDelete(model: any): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = model;

        return this.http.put(this.config.serverWithApiUrl + 'customer/viewerexecution/delete-in-batch', data, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }


    public GetByDetailsByInsertion(insertion: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.get(this.config.serverWithApiUrl + 'customer/viewerexecution/getdetailsbyinsertion/' + insertion, options).pipe(
            map((response: Response) => {
                return response.json();
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }

    public Delete(insertion: string, type: string, player: string): Observable<any> {
        // add authorization header with jwt token
        const headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken(), 'Content-Type': 'application/json', 'Accept': 'application/json', 'Accept-Language': 'pt-BR' });
        const options = new RequestOptions({ headers: headers });
        const data = {};

        return this.http.delete(this.config.serverWithApiUrl + 'customer/viewerexecution/delete/' + insertion + '/' + player + '/' + type, options).pipe(
            map((response: Response) => {
                return true;
            }),
            catchError((error: any) => {
                return observableThrowError(error.json() || error.json().error || 'Server error');
            }), );
    }
}
