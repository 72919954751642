export class ViewerExecutiontModel {
    public group: boolean;
    public playerIdentificator: string;
    public date: string;

    public constructor( data: any = {}) {
        this.group = data.group || '';
        this.playerIdentificator = data.playerIdentificator || '';
        this.date = data.date || '';
    }
}
