import { Component, Optional, ViewEncapsulation, OnInit } from '@angular/core';
import { AuthService, UserService, NotificationService, NotificationApiService } from '../../../services';
import { Router } from '@angular/router';
import { Configuration } from '../../../app.constants';
import { UserModel, UserPasswordModel } from '../../../models';
import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

declare var jquery: any;
declare var $: any;
declare var screenfull: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

    // @ViewChild('dialog') public swalDialog: SwalComponent;

    public userInfo: UserModel;
    public userPassword: UserPasswordModel = new UserPasswordModel();
    public notifications: any[] = [];

    constructor(public auth: AuthService, private userService: UserService, private router: Router, private config: Configuration,
        private notificationService: NotificationService, private notificationApiService: NotificationApiService) {

        this.userInfo = new UserModel();
        this.loadNotifications();
    }

    public ngOnInit() {
        // let timer = Observable.timer(15000, 15000);
        // timer.subscribe(t=> {
        //     this.loadNotifications();
        // });

        this.userInfo = new UserModel(this.auth.getUserData());
    }

    public loadNotifications() {
        this.notificationApiService.getLastAssocied().subscribe(
            response => {
                this.notifications = response;
            },
            error => {

            }
        )
    }

    public logout = (): void => {
        let instance = this;

        this.notificationService.showConfirmationMessage('', 'Deseja realmente sair?', 'question', function() {
            instance.auth.logout();
            instance.router.navigate(['/login']);

            //instance.notificationService.showInfoNotification('Você foi deslogado.');
        }, function() {
            //instance.notificationService.showInfoNotification('Logout cancelado');
        });
    }

    public changePassword(form: any, model: UserPasswordModel, isValid: boolean) {

        if (!isValid) {
            return;
        }

        this.userService.changePassword(model).subscribe(
            response => {
                this.notificationService.showMessage('', 'Senha alterada com sucesso!', 'success', function() {
                    $('#modalChangePassword').modal('hide');
                });
            },
            err => {
                console.log('err', err);
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )
    }

    requestFullscreen() {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }
}
