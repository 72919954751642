import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NotificationService, PlayerService, CentralContentService, AdvertisingContractService } from '../../../services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import {
  TipContentModel,
  NewsContentModel,
  OffersContentModel,
  ImageContentModel,
  MultimediaContentModel,
  JobOfferContentModel,
  TablePriceContentModel,
  HtmlContentModel,
  YoutubeVideoContentModel,
  WebPageContentModel
} from 'app/models';
import { Router, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpResponse, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

declare var $: any;

@Component({
  templateUrl: './envio-de-conteudo.component.html',
  styleUrls: ['./envio-de-conteudo.component.css']
})
export class EnvioDeConteudoComponent extends PageBaseComponent {
  public wizardStep = 1;
  public responseStatus = [];
  public tiposDeConteudo = [
    { id: 1, title: 'Notícia', image: 'Noticia.png' },
    { id: 2, title: 'Ofertas e Produtos', image: 'promotion.png' },
    { id: 3, title: 'Imagem', image: 'photo.png' },
    { id: 4, title: 'Vídeo', image: 'camera.png' },
    { id: 5, title: 'Aviso de emprego', image: 'worker.png' },
    { id: 6, title: 'Tabela de preços', image: 'price.png' },
    { id: 7, title: 'Conteúdo HTML', image: 'Html.png' },
    { id: 8, title: 'Contrato/Patrocínio', image: 'Comercial.png' },
    { id: 9, title: 'Youtube Vídeo', image: 'youtube.png' },
    { id: 10, title: 'Dicas', image: 'tips.png' },
    { id: 11, title: 'Página da internet', image: 'internet.png' }
  ];
  public conteudo: any;
  public conteudos: any[] = [];

  public tipoSelecionado = { id: 0, title: '', image: '' };
  public players: any[] = [];
  public hasSelectedFiles = false;
  public fileBytes = '';
  public urlFile = '';
  public selectedPlayers: any[] = [];
  public tiposValidos = '.jpg,.jpeg,.png,.mp4,.swf';
  public showLoading = false;
  public percentDone = 0;

  constructor(
    private notificationService: NotificationService,
    private playerService: PlayerService,
    private centralContentService: CentralContentService,
    private advertisingContractService: AdvertisingContractService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super();

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // console.log(val);//
      }
    });

    this.playerService.getAllAssociatedSimpleList().subscribe(
      response => {
        this.players = response;
        this.initChosen(function() {});
      },
      err => {
        if (err.status === 401 || err.status === 403) return;
        this.notificationService.showErrorMessage(err);
      }
    );
  }

  ngAfterViewInit() {
    this.initChosen(function() {});
    this.initDatePicker();
  }

  public selecionarTipo(item) {
    this.tipoSelecionado = item;
    if (
      this.tipoSelecionado.id == 1 ||
      this.tipoSelecionado.id == 2 ||
      this.tipoSelecionado.id == 3 ||
      this.tipoSelecionado.id == 6 ||
      this.tipoSelecionado.id == 10
    ) {
      this.tiposValidos = '.jpg,.jpeg,.png';
    } else if (this.tipoSelecionado.id == 4) {
      this.tiposValidos = '.avi,.mp4,.wmv,.swf';
    } else if (this.tipoSelecionado.id == 9) {
      this.tiposValidos = '.avi,.mp4,.wmv,.swf';
    } else {
      this.tiposValidos = '';
    }

    /*if(this.conteudos.length == 0) {
            this.conteudos.push({
                content: new NewsContentModel()
            });
        }*/

    this.wizardStep++;
  }

  public nextStep() {
    if (this.wizardStep == 2) {
      this.sendContent();
    } else {
      this.wizardStep++;
      this.initChosen(function() {});
    }
  }

  public comeBackStep() {
    let instance = this;

    if (this.conteudos.length > 0) {
      this.notificationService.showConfirmationMessage(
        '',
        'Deseja realmente sair?',
        'question',
        function() {
          instance.conteudos = [];

          instance.wizardStep--;
          instance.initChosen(function() {});
        },
        function() {}
      );
    } else {
      instance.conteudos = [];

      instance.wizardStep--;
      instance.initChosen(function() {});
    }
  }

  public openFileUpload(id) {
    $('#' + id).click();
  }

  public sendContent() {
    let instance = this;
    let cts: any[] = [];

    let isValid = true;

    if (this.conteudos.length == 0) {
      instance.notificationService.showErrorNotification(
        'Nenhum conteúdo selecionado!'
      );
      return;
    }

    this.conteudos.forEach(element => {
      let newContent = { ...element.content };
      newContent.InitialDate = newContent.InitialDate;
      newContent.EndDate = newContent.EndDate;

      cts.push(newContent);
      isValid = isValid && element.content.isValid();
    });

    if (isValid) {
      instance.showLoading = true;

      if (this.tipoSelecionado.id == 1) {
        this.centralContentService.sendNewsContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 2) {
        this.centralContentService.sendOffersContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 3) {
        this.centralContentService.sendImageContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 4) {
        this.centralContentService.sendMultimediaContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 5) {
        this.centralContentService.sendJobOfferContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 6) {
        this.centralContentService.sendTablePriceContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 7) {
        this.centralContentService.sendHtmlContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id == 9) {
        let linkYoutubeOK = true;
        this.conteudos.forEach(element => {
          const newContent = { ...element.content };
          if (
            !newContent.UrlVideo.includes('https://www.youtube.com/watch?v=')
          ) {
            linkYoutubeOK = false;
            instance.notificationService.showErrorNotification(
              'O link do youtube é inválido! Deve começar com https://www.youtube.com/watch?v='
            );
            instance.showLoading = false;
          }
        });

        if (linkYoutubeOK) {
          this.centralContentService.sendYoutubeVideoContent(cts).subscribe(
            event => {
              instance.processEvent(event);
            },
            error => {
              instance.processError(error);
            }
          );
        }
      } else if (this.tipoSelecionado.id == 10) {
        this.centralContentService.sendTipContent(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      } else if (this.tipoSelecionado.id === 11) {
        this.centralContentService.sendWebPage(cts).subscribe(
          event => {
            instance.processEvent(event);
          },
          error => {
            instance.processError(error);
          }
        );
      }
    } else {
      this.notificationService.showErrorNotification(
        'Preencha todos os campos obrigatórios!'
      );
    }
  }

  processEvent(event: any) {
    console.log('processEvent', event);
    if (event.type == HttpEventType.UploadProgress) {
      this.percentDone = Math.round((100 * event.loaded) / event.total);
    } else if (event instanceof HttpResponse) {
      this.wizardStep++;
      this.showLoading = false;
    } else if (event instanceof HttpErrorResponse) {
      console.log('err');
      this.notificationService.showErrorNotification(
        'Não foi possível fazer o envio no momento!'
      );
      this.showLoading = false;
    }
  }

  processError(error: any) {
    console.log('err', error);
    this.notificationService.showErrorNotification(
      'Não foi possível fazer o envio no momento!'
    );
    this.showLoading = false;
  }

  addContentModel() {
    if (this.tipoSelecionado.id == 1) {
      this.tiposValidos = '.jpg,.jpeg,.png';
      this.conteudos.push({
        content: new NewsContentModel()
      });
    } else if (this.tipoSelecionado.id == 2) {
      this.tiposValidos = '.jpg,.jpeg,.png';
      this.conteudos.push({
        content: new OffersContentModel()
      });
    } else if (this.tipoSelecionado.id == 3) {
      this.tiposValidos = '.jpg,.jpeg,.png';
      this.conteudos.push({
        content: new ImageContentModel()
      });
    } else if (this.tipoSelecionado.id == 4) {
      this.tiposValidos = '.AVI,.MP4,.WMV,.SWF';
      this.conteudos.push({
        content: new MultimediaContentModel()
      });
    } else if (this.tipoSelecionado.id == 5) {
      this.tiposValidos = '';
      this.conteudos.push({
        content: new JobOfferContentModel()
      });
    } else if (this.tipoSelecionado.id == 6) {
      this.tiposValidos = '.jpg,.jpeg,.png';
      this.conteudos.push({
        content: new TablePriceContentModel()
      });
    } else if (this.tipoSelecionado.id == 7) {
      this.tiposValidos = '';
      this.conteudos.push({
        content: new HtmlContentModel()
      });
    }
    // else if(this.tipoSelecionado.id == 8){
    //     this.conteudos.push({
    //         content: new ImageContentModel()
    //     })
    // }
    else if (this.tipoSelecionado.id == 9) {
      this.conteudos.push({
        content: new YoutubeVideoContentModel()
      });
    } else if (this.tipoSelecionado.id == 10) {
      this.conteudos.push({
        content: new TipContentModel()
      });
    }
  }

  addConteudo() {
    if (this.tipoSelecionado.id == 5) {
      let jobOffercontent = new JobOfferContentModel();
      this.conteudos.push({
        content: jobOffercontent
      });
    } else if (this.tipoSelecionado.id == 7) {
      let htmlcontent = new HtmlContentModel();
      this.conteudos.push({
        content: htmlcontent
      });
    } else if (this.tipoSelecionado.id == 9) {
      let youtubeContent = new YoutubeVideoContentModel();
      this.conteudos.push({
        content: youtubeContent
      });
    } else if (this.tipoSelecionado.id == 11) {
      this.conteudos.push({
        content: new WebPageContentModel()
      });
    }
  }

  removeContent(item) {
    this.conteudos = this.conteudos.filter(
      e => e.content.TempId != item.content.TempId
    );
  }

  uploadFileChange(event: any = null) {
    let instance = this;
    let reader = new FileReader();

    reader.onload = function() {
      instance.conteudo.fileBytes = reader.result;
    };

    if (this.tipoSelecionado.id == 1) {
      for (let i = 0; i < event.target.files.length; i++) {
        let newsContent = new NewsContentModel();
        newsContent.loadUploadFile(event.target.files[i]);
        newsContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);
        this.conteudos.push({
          content: newsContent
        });
      }
    } else if (this.tipoSelecionado.id == 2) {
      for (let i = 0; i < event.target.files.length; i++) {
        let offerContent = new OffersContentModel();
        offerContent.loadUploadFile(event.target.files[i]);
        offerContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: offerContent
        });
      }
    } else if (this.tipoSelecionado.id == 3) {
      for (let i = 0; i < event.target.files.length; i++) {
        let imageContent = new ImageContentModel();
        imageContent.loadUploadFile(event.target.files[i]);
        imageContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: imageContent
        });
      }
    } else if (this.tipoSelecionado.id == 4) {
      for (let i = 0; i < event.target.files.length; i++) {
        let multimediaContent = new MultimediaContentModel();
        multimediaContent.loadUploadFile(event.target.files[i]);
        multimediaContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);
        multimediaContent.Duration = 15;
        this.conteudos.push({
          content: multimediaContent
        });
      }
    } else if (this.tipoSelecionado.id == 5) {
      for (let i = 0; i < event.target.files.length; i++) {
        let jobContent = new JobOfferContentModel();
        jobContent.loadUploadFile(event.target.files[i]);
        jobContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: jobContent
        });
      }
    } else if (this.tipoSelecionado.id == 6) {
      for (let i = 0; i < event.target.files.length; i++) {
        let tablePriceContent = new TablePriceContentModel();
        tablePriceContent.loadUploadFile(event.target.files[i]);
        tablePriceContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: tablePriceContent
        });
      }
    } else if (this.tipoSelecionado.id == 7) {
      for (let i = 0; i < event.target.files.length; i++) {
        let htmlContent = new HtmlContentModel();
        htmlContent.loadUploadFile(event.target.files[i]);
        htmlContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: htmlContent
        });
      }
    }
    // else if(this.tipoSelecionado.id == 8){
    //     for(var i = 0; i < event.target.files.length; i++) {
    //         var offerContent = new OffersContentModel();
    //         offerContent.loadUploadFile(event.target.files[i]);
    //         offerContent.FileExtension = '.' + this.getFileExtension(event.target.files[i].name);

    //         this.conteudos.push({
    //             content: offerContent
    //         });
    //     }
    // }
    else if (this.tipoSelecionado.id == 9) {
      for (let i = 0; i < event.target.files.length; i++) {
        let youtubeContent = new YoutubeVideoContentModel();
        youtubeContent.loadUploadFile(event.target.files[i]);
        youtubeContent.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: youtubeContent
        });
      }
    } else if (this.tipoSelecionado.id == 10) {
      for (let i = 0; i < event.target.files.length; i++) {
        let tipContentModel = new TipContentModel();
        tipContentModel.loadUploadFile(event.target.files[i]);
        tipContentModel.FileExtension =
          '.' + this.getFileExtension(event.target.files[i].name);

        this.conteudos.push({
          content: tipContentModel
        });
      }
    }
  }
}
