import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MenuItem } from '../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { OccurrenceService, NotificationService } from '../../../services';
import { OccurrenceNoteModel } from 'app/models/occorrence-note';
import { PageBaseComponent } from 'app/components/pages/page-base.component';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './ocorrencias-suporte-details.component.html',
    styleUrls: ['./ocorrencias-suporte-details.component.css'],
})
export class OcorrenciasSuporteDetailsComponent extends PageBaseComponent  {

    public showLoading = true;
    public occurrenceDetails: any;

    public occurrenceIsClosed = true;

    public occurrenceNoteData: OccurrenceNoteModel = new OccurrenceNoteModel({
        title: '',
        text: ''
    });

    constructor(private activatedRoute: ActivatedRoute, private occurrenceService: OccurrenceService, private notificationService: NotificationService) {
        super();
    }

    public ngOnInit() {
        const id = this.activatedRoute.snapshot.params['id'];
        if (id) {
            this.loadOccurrencesDetails(id);
        }
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {

    }

    public loadOccurrencesDetails(identificator: string) {
        this.occurrenceService.getDetails(identificator).subscribe(
            response => {
                this.occurrenceDetails = response;
                this.occurrenceIsClosed = this.occurrenceDetails.Closed;
                this.showLoading = false;
            },
            error => {
                this.showLoading = false;
            }
        )
    }

    public clearAndDismissModal() {

        $('#title').val('');
        $('#text').val('');
        $('#modalNovaOcorrencia').modal('hide');
    }

    public closeOccurrence() {

        const instance = this;

        this.notificationService.showConfirmationMessage('Fechando a ocorrência', 'Deseja realmente fechar esta ocorrência?', 'question', function() {
            instance.occurrenceService.close(instance.activatedRoute.snapshot.params['id']).subscribe(
                response => {
                    instance.occurrenceIsClosed = true;
                    instance.notificationService.showSuccessNotification('Ocorrência fechada');
                },
                err => {
                    instance.notificationService.showErrorNotification('', 'Erro ao fechar ocorrência');
                }
            );
        }, function() {
            // Do nothing
        });
    }

    public addNote(form: any, model: any, isValid: boolean) {

        if (!isValid) {
            return;
        }

        model.Identificator = this.activatedRoute.snapshot.params['id'];

        this.showLoading = true;
        this.occurrenceService.addNote(model).subscribe(
            response => {
                this.clearAndDismissModal();
                this.notificationService.showSuccessNotification('Nova nota adicionada');
                this.loadOccurrencesDetails(model.Identificator);
            },
            error => {
                this.clearAndDismissModal();
                this.showLoading = false;
                this.notificationService.showMessage('', error.Message, 'error');
            }
        )
    }
}
