
import {timer as observableTimer,  Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService } from '../../../services';
import { PlayerModel } from '../../../models';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './meus-paineis.component.html',
    styleUrls: ['./meus-paineis.component.css'],
})
export class MeusPaineisComponent {

    public players: PlayerModel[] = [];
    public playersFiltered: PlayerModel[] = [];
    public selectedImageSrc: String = '';
    public filterActive: any = 0;
    public filterName: any = '';
    public showLoading = true;

    constructor(private playerService: PlayerService) {
        this.loadData();
    }

    public ngOnInit() {
        const timer = observableTimer(0, 15000);
        timer.subscribe(t => {
            this.loadData();
        });
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();
    }

    ngAfterViewInit() {

    }

    public loadData() {
        this.playerService.getAllAssocied().subscribe(
            response => {
                this.players = response as PlayerModel[];
                this.filterPlayers();
            },
            err => {
                this.showLoading = false;
            }
        )
    }

    public getFreeSpacePercent(model) {
        // var total = model.FreeSpace + model.UsedSpace;
        // return (100 - (100 * model.FreeSpace / (total))).toFixed(0);
        return model.UsedSpace;
    }

    public openDetailModal() {
        $('#modalDetails').modal('show');
    }

    public showModalImage(imgSrc) {
        this.selectedImageSrc = imgSrc;
        $('#modalImage').modal('show');
    }

    public filterActiveChange(event) {
        this.filterActive = event.target.value;
        this.filterPlayers();
    }

    filterPlayers() {
        if (this.filterActive == 1) {
            this.playersFiltered = this.players.filter(e => !e.Blocked).filter(e => e.Name.toLowerCase().indexOf(this.filterName.toLowerCase()) >= 0);
        } else {
            this.playersFiltered = this.players.filter(e => e.Name.toLowerCase().indexOf(this.filterName) >= 0);
        }

        this.showLoading = false;
    }
}
