import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PlayerService, NotificationService, OperationLogService } from 'app/services';
import { PageBaseComponent } from 'app/components/pages/page-base.component';
import { OperationLogModel } from 'app/models/operation-log';
import { DatePipe } from '@angular/common';


declare var jquery: any;
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './log-operacional.component.html',
    styleUrls: ['./log-operacional.component.css'],
})
export class LogOperacionalComponent extends PageBaseComponent {

    public players: any[] = [];
    public showLoading = false;
    public logItens: any[] = [];
    public tiposDeConteudo = [
        { id: 'All', title: 'Todos' },
        { id: 'News', title: 'Notícia' },
        { id: 'RSSNews', title: 'Notícia RSS' },
        { id: 'OffersProducts', title: 'Ofertas e Produtos', },
        { id: 'Image', title: 'Imagem', },
        { id: 'Multimidia', title: 'Multimídia', },
        { id: 'Job', title: 'Aviso de emprego', },
        { id: 'PriceTable', title: 'Tabela de preços', },
        { id: 'HTML', title: 'Conteúdo HTML',  },
        { id: 'Contract', title: 'Contrato/Patrocínio', },
    ];

    public operationLogData: OperationLogModel = new OperationLogModel({
        playerIdentificator: '',
        type: 'All',
        date: ''
    });

    constructor(private operationLogService: OperationLogService,
        private playerService: PlayerService,
        private notificationService: NotificationService,
        private datePipe: DatePipe) {
        super();
    }

    public ngOnInit() {
        this.playerService.getAllAssociatedSimpleList().subscribe(
            response => {
                this.players = response;

                setTimeout(() => {
                    this.initChosen(function(){

                    });
                }, 0);
            }, err => {
                this.notificationService.showMessage('', err.Message, 'error');
            }
        )

        this.operationLogData.date = this.getCurrentDate();
    }

    public ngOnDestroy() {
        // removing the header
        //this.breadServ.clear();

    }

    ngAfterViewInit() {
        this.initChosen(function() {

        });
        this.initDatePicker();
    }

    public print()
    {
        window.print();
    }

    public getLogs() {
        let date = $('#operationLogData').val();
        let selectedPlayers = $('#selectedPlayers').val();

        if (date == '' || date == null || selectedPlayers == '' || selectedPlayers == null) {
            this.notificationService.showErrorNotification('Por favor preencha os filtros.');
        } else {
            this.showLoading = true;
            this.operationLogService.getByFilter(this.datePipe.transform(this.formatDateToParameterService(date), 'dd-MM-yyyy'), selectedPlayers, this.operationLogData.type).subscribe(
                response => {
                    this.logItens = response;
                    this.showLoading = false;
                }, err => {
                    this.showLoading = false;
                    this.notificationService.showMessage('', err.Message, 'error');
                }
            );
        }
    }
}
